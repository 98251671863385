import i18n from '@/i18n';

const configFormBasicLP = () => {
    return {
        configFieldSelectCampaign: {
            trackBy: 'id',
            label: 'name',
            errorField: i18n.t('pageReturnPrize.nameCampaign'),
            valueSync: null,
            loading: false,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },
        configFieldNameLandingPage: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingBasic.nameLandingPage'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldSaleStaff: {
            trackBy: 'id',
            label: 'name',
            isRemove: true,
            error: false,
            errorField: i18n.t('landingPage.settingBasic.businessAssistant'),
            valueSync: null,
            typesValidate: []
        },
        configFieldPICinstructions: {
            trackBy: 'id',
            label: 'name',
            isRemove: true,
            error: false,
            errorField: i18n.t('landingPage.settingBasic.picInstructions'),
            valueSync: null,
            typesValidate: []
        },
        configFieldCampaignStartDateTime: {
            minuteStep: 1,
            placeholderTime: '',
            errorText: '',
            valueSync: '',
            error: false,
            typesValidate: ['dateTime'],
            errorField: i18n.t('landingPage.settingBasic.campaignStartDate')
        },
        configFieldCampaignEndDateTime: {
            minuteStep: 1,
            placeholderTime: '',
            errorText: '',
            valueSync: '',
            error: false,
            typesValidate: ['dateTime'],
            errorField: i18n.t('landingPage.settingBasic.campaignEndDate')
        },
        configFieldLPStartDateTime: {
            minuteStep: 1,
            placeholderTime: '',
            errorText: '',
            valueSync: '',
            error: false,
            typesValidate: ['dateTime'],
            errorField: i18n.t('landingPage.settingBasic.landingPageStartDate')
        },
        configFieldLPEndDateTime: {
            minuteStep: 1,
            placeholderTime: '',
            errorText: '',
            valueSync: '',
            error: false,
            typesValidate: ['dateTime'],
            errorField: i18n.t('landingPage.settingBasic.landingPageEndDate')
        },
        configFieldKeywordSite: {
            maxLength: 255,
            error: false,
            errorField: i18n.t('landingPage.settingBasic.keyword'),
            errorCustomMess: '',
            valueSync: [],
            typesValidate: []
        },
        configFieldDescriptions: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingBasic.description'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldUrlPublic: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingBasic.desUrlPublic'),
            errorCustomMess: '',
            valueSync: '',
            placeholder: 'campaign-abc',
            typesValidate: ['required', 'max255', 'sub-domain']
        }
    };
};
export { configFormBasicLP };
