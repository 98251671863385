<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import DatePicker from 'vue2-datepicker';
import Footer from '@/components/Footer/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

import { regexNumber } from '@/utils/regex';
import { checkPermission, isValidDate, masterMethods } from '@/state/helpers';
import { maxLength, required } from 'vuelidate/lib/validators';
import { showMessage } from '@/utils/messages';

export default {
    page: {
        title: '休日',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        DatePicker,
        Layout,
        Footer,
        LoadingIcon
    },
    watch: {
        'form.start_date': function (newName) {
            if (newName) {
                const date = newName.split('-');
                this.form.start_date_y = date[0];
                this.form.start_date_m = date[1];
                this.form.start_date_d = date[2];
            }
        },
        'form.end_date': function (newName) {
            if (newName) {
                const date = newName.split('-');
                this.form.end_date_y = date[0];
                this.form.end_date_m = date[1];
                this.form.end_date_d = date[2];
            }
        }
    },
    data() {
        return {
            validateList: [],
            validateTitle: false,
            isLoading: false,
            submitted: false,
            submittedList: false,
            disabled: false,
            is_editable: false,
            loading: false,
            listData: [],
            dataUpdate: '',
            form: {
                title: '',
                start_date: '',
                start_date_y: '',
                start_date_m: '',
                start_date_d: '',
                end_date: '',
                end_date_y: '',
                end_date_m: '',
                end_date_d: ''
            },
            customFormatDate: 'YYYY-MM-DD'
        };
    },
    validations: {
        listData: {
            $each: {
                title: { required, maxLength: maxLength(255) },
                start_date: {
                    required(value) {
                        if (value) {
                            var parts = value.split('-');
                            var day = parts[2];
                            var month = parts[1];
                            var year = parts[0];
                            if (day || month || year) {
                                return true;
                            }
                        }
                        return false;
                    },
                    validate(value) {
                        if (value) {
                            var parts = value.split('-');
                            var day = parts[2];
                            var month = parts[1];
                            var year = parts[0];

                            var result = day + '/' + month + '/' + year;
                            return isValidDate(result);
                        }
                        return true;
                    }
                },
                end_date: {
                    required(value) {
                        if (value) {
                            var parts = value.split('-');
                            var day = parts[2];
                            var month = parts[1];
                            var year = parts[0];
                            if (day || month || year) {
                                return true;
                            }
                        }
                        return false;
                    },
                    validate(value) {
                        if (value) {
                            var parts = value.split('-');
                            var day = parts[2];
                            var month = parts[1];
                            var year = parts[0];

                            var result = day + '/' + month + '/' + year;
                            return isValidDate(result);
                        }
                        return true;
                    }
                }
            }
        },
        form: {
            title: { required, maxLength: maxLength(255) },
            start_date: {
                required() {
                    if (this.form.start_date_m || this.form.start_date_d || this.form.start_date_y) {
                        return true;
                    }
                    return false;
                },
                validate() {
                    if (this.form.start_date_m || this.form.start_date_d || this.form.start_date_y) {
                        return isValidDate(this.form.start_date_d + '/' + this.form.start_date_m + '/' + this.form.start_date_y);
                    }
                    return true;
                },
                checkDate() {
                    let validDateEnd = isValidDate(this.form.end_date_d + '/' + this.form.end_date_m + '/' + this.form.end_date_y);
                    let validDateStart = isValidDate(this.form.start_date_d + '/' + this.form.start_date_m + '/' + this.form.start_date_y);

                    if (validDateEnd && validDateStart) {
                        const startDateObj = new Date(this.form.start_date_y + '/' + this.form.start_date_m + '/' + this.form.start_date_d);
                        const endDateObj = new Date(this.form.end_date_y + '/' + this.form.end_date_m + '/' + this.form.end_date_d);
                        if (startDateObj.getTime() > endDateObj.getTime()) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                    return true;
                }
            },
            end_date: {
                required() {
                    if (this.form.end_date_m || this.form.end_date_d || this.form.end_date_y) {
                        return true;
                    }
                    return false;
                },
                validate() {
                    if (this.form.end_date_m || this.form.end_date_d || this.form.end_date_y) {
                        return isValidDate(this.form.end_date_d + '/' + this.form.end_date_m + '/' + this.form.end_date_y);
                    }
                    return true;
                },
                checkDate() {
                    let validDateEnd = isValidDate(this.form.end_date_d + '/' + this.form.end_date_m + '/' + this.form.end_date_y);
                    let validDateStart = isValidDate(this.form.start_date_d + '/' + this.form.start_date_m + '/' + this.form.start_date_y);

                    if (validDateEnd && validDateStart) {
                        const startDateObj = new Date(this.form.start_date_y + '/' + this.form.start_date_m + '/' + this.form.start_date_d);
                        const endDateObj = new Date(this.form.end_date_y + '/' + this.form.end_date_m + '/' + this.form.end_date_d);
                        if (startDateObj.getTime() > endDateObj.getTime()) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                    return true;
                }
            }
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        ...masterMethods,
        goToEdit(path, key) {
            this.$router.push({ path: path, query: { key: key } });
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        checkDate(data) {
            let validDateEnd = isValidDate(data.end_date_d + '/' + data.end_date_m + '/' + data.end_date_y);
            let validDateStart = isValidDate(data.start_date_d + '/' + data.start_date_m + '/' + data.start_date_y);

            if (validDateEnd && validDateStart) {
                const startDateObj = new Date(data.start_date_y + '/' + data.start_date_m + '/' + data.start_date_d);
                const endDateObj = new Date(data.end_date_y + '/' + data.end_date_m + '/' + data.end_date_d);
                if (startDateObj.getTime() > endDateObj.getTime()) {
                    return false;
                } else {
                    return true;
                }
            }
            return true;
        },
        formSubmit() {
            this.submitted = true;
            this.loading = true;
            this.$v.form.$touch();
            if (this.$v.form.$invalid) {
                return;
            } else {
                this.register();
            }
        },
        formSubmitList() {
            if (this.listData.length <= 0 || (this.dataUpdate == '' && this.dataUpdate != 0)) return;

            this.submittedList = true;
            this.$v.$touch();
            if (this.$v.listData.$invalid) {
                return;
            } else {
                this.update();
            }
        },
        getList() {
            this.isLoading = true;
            this.listHolidayYear().then((data) => {
                if (data.code == 200) {
                    data.data.forEach((item, k) => {
                        const date = item.start_date.split('-');
                        data.data[k].start_date_y = date[0];
                        data.data[k].start_date_m = date[1];
                        data.data[k].start_date_d = date[2];

                        const end_date = item.end_date.split('-');
                        data.data[k].end_date_y = end_date[0];
                        data.data[k].end_date_m = end_date[1];
                        data.data[k].end_date_d = end_date[2];
                    });
                    this.listData = data.data;
                }
                this.isLoading = false;
            });
        },
        register() {
            if (this.form.title) {
                this.validateTitle = false;
                let i = this.listData.findIndex((x) => x.title == this.form.title);
                if (i >= 0) {
                    this.validateTitle = true;
                    return;
                }

                let object = JSON.parse(JSON.stringify(this.form));
                object.start_date = object.start_date_y + '-' + object.start_date_m + '-' + object.start_date_d;
                object.end_date = object.end_date_y + '-' + object.end_date_m + '-' + object.end_date_d;
                this.registerHolidayYear(object).then((data) => {
                    if (data && data.code == 200) {
                        this.getList();
                        this.form = {
                            title: '',
                            start_date: '',
                            start_date_y: '',
                            start_date_m: '',
                            start_date_d: '',
                            end_date: '',
                            end_date_y: '',
                            end_date_m: '',
                            end_date_d: ''
                        };
                        this.submitted = false;
                        showMessage(data.code, this.$bvToast, '登録されました。');
                    }
                });
            }
        },
        edit(item, key) {
            if (!item.disabled) {
                this.dataUpdate = key;

                this.listData[key].disabled = true;
                this.listData.forEach((item, k) => {
                    const end_date = item.end_date.split('-');
                    this.listData[k].end_date_y = end_date[0];
                    this.listData[k].end_date_m = end_date[1];
                    this.listData[k].end_date_d = end_date[2];

                    const start_date = item.start_date.split('-');
                    this.listData[k].start_date_y = start_date[0];
                    this.listData[k].start_date_m = start_date[1];
                    this.listData[k].start_date_d = start_date[2];
                });
                this.listData = [].concat(this.listData);
            }
        },
        findDuplicates(data) {
            const titles = {};
            const duplicates = [];

            data.forEach((item) => {
                if (titles[item.title]) {
                    duplicates.push(item);
                } else {
                    titles[item.title] = true;
                }
            });

            return duplicates;
        },
        update() {
            let object = JSON.parse(JSON.stringify(this.listData[this.dataUpdate]));
            object.start_date = object.start_date_y + '-' + object.start_date_m + '-' + object.start_date_d;
            object.end_date = object.end_date_y + '-' + object.end_date_m + '-' + object.end_date_d;
            let index = this.findDuplicates(this.listData);
            if (index.length > 0) {
                this.validateList[this.dataUpdate] = 'この年度がすでに登録されています。';
                return;
            } else {
                this.validateList[this.dataUpdate] = '';
            }

            let j = this.listData.findIndex((x) => {
                if (!this.checkDate(x)) return true;
            });
            if (j >= 0) {
                return;
            }
            this.updateHolidayYear(object).then((data) => {
                this.listData[this.dataUpdate].disabled = false;
                this.dataUpdate = '';
                if (data.code == 200) showMessage(data.code, this.$bvToast, '更新されました。');
                this.listData = [].concat(this.listData);
            });
        },
        updateDate(item, k) {
            this.listData[k].end_date = item.end_date_y + '-' + item.end_date_m + '-' + item.end_date_d;
        },
        updateDateStart(item, k) {
            this.listData[k].start_date = item.start_date_y + '-' + item.start_date_m + '-' + item.start_date_d;
        },
        deleteObject(data) {
            Swal.fire({
                title: '削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteMasterHolidayYear(data).then((res) => {
                        if (res.code == 200) {
                            showMessage(res.code, this.$bvToast, '削除されました。');
                            this.getList();
                        }
                    });
                }
            });
        },
        preventInputE(event) {
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
        },
        inputNumber(key, index) {
            if (index >= 0) {
                this.listData[index][key] = regexNumber(this.listData[index][key]);
            } else {
                this.form[key] = regexNumber(this.form[key]);
            }
        },
        changeDate(item, k) {
            const end_date = item.end_date.split('-');
            this.listData[k].end_date_y = end_date[0];
            this.listData[k].end_date_m = end_date[1];
            this.listData[k].end_date_d = end_date[2];

            const start_date = item.start_date.split('-');
            this.listData[k].start_date_y = start_date[0];
            this.listData[k].start_date_m = start_date[1];
            this.listData[k].start_date_d = start_date[2];
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!isLoading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="formSubmitList" autocomplete="off">
                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered">
                                    <thead>
                                        <tr>
                                            <th>年度</th>
                                            <th>日付</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, k) in listData" :key="item.id">
                                            <td>
                                                <div>
                                                    <input
                                                        type="text"
                                                        @input="inputNumber('title', k)"
                                                        @keypress="preventInputE"
                                                        :disabled="!item.disabled"
                                                        v-model="item.title"
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': (submittedList && $v.listData.$each[k].title.$error) || validateList[k]
                                                        }"
                                                    />
                                                    <div
                                                        v-if="(submittedList && $v.listData.$each[k].title.$error) || validateList[k]"
                                                        class="invalid-feedback"
                                                    >
                                                        <span v-if="!$v.listData.$each[k].title.required">{{
                                                            $t('validateField.required', {
                                                                field: '年度'
                                                            })
                                                        }}</span>
                                                        <span v-if="!$v.listData.$each[k].title.maxLength">
                                                            {{
                                                                $t('validateField.max255', {
                                                                    field: '年度'
                                                                })
                                                            }}
                                                        </span>
                                                        <span v-if="validateList[k]">この年度がすでに登録されています。</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            :disabled="!item.disabled"
                                                            v-model="item.start_date_y"
                                                            style="width: 80px; padding-right: 0 !important"
                                                            @change="updateDateStart(item, k)"
                                                            class="form-control mr-2"
                                                            :class="{
                                                                'is-invalid':
                                                                    submittedList && ($v.listData.$each[k].start_date.$error || !checkDate(item))
                                                            }"
                                                        />
                                                        年
                                                        <input
                                                            type="text"
                                                            :disabled="!item.disabled"
                                                            v-model="item.start_date_m"
                                                            @change="updateDateStart(item, k)"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid':
                                                                    submittedList && ($v.listData.$each[k].start_date.$error || !checkDate(item))
                                                            }"
                                                        />
                                                        月
                                                        <input
                                                            type="text"
                                                            :disabled="!item.disabled"
                                                            v-model="item.start_date_d"
                                                            @change="updateDateStart(item, k)"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid':
                                                                    submittedList && ($v.listData.$each[k].start_date.$error || !checkDate(item))
                                                            }"
                                                        />
                                                        日
                                                        <div class="btn-sm btn btn-info mx-2 box-date" style="min-width: 80px">
                                                            カレンダー
                                                            <date-picker
                                                                @close="changeDate(item, k)"
                                                                :disabled="!item.disabled"
                                                                v-model="item.start_date"
                                                                :value-type="customFormatDate"
                                                                :first-day-of-week="1"
                                                                lang="ja"
                                                            ></date-picker>
                                                        </div>
                                                    </div>
                                                    <span
                                                        :class="{
                                                            'is-invalid':
                                                                submittedList && ($v.listData.$each[k].start_date.$error || !checkDate(item))
                                                        }"
                                                    ></span>
                                                    <div
                                                        v-if="submittedList && ($v.listData.$each[k].start_date.$error || !checkDate(item))"
                                                        class="invalid-feedback"
                                                    >
                                                        <span v-if="!$v.listData.$each[k].start_date.required">形式が正しくありません。</span>
                                                        <span v-if="!$v.listData.$each[k].start_date.validate">形式が正しくありません。</span>
                                                        <span v-if="!checkDate(item)">形式が正しくありません。</span>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class="d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            :disabled="!item.disabled"
                                                            v-model="item.end_date_y"
                                                            style="width: 80px; padding-right: 0 !important"
                                                            @change="updateDate(item, k)"
                                                            class="form-control mr-2"
                                                            :class="{
                                                                'is-invalid':
                                                                    submittedList && ($v.listData.$each[k].end_date.$error || !checkDate(item))
                                                            }"
                                                        />
                                                        年
                                                        <input
                                                            type="text"
                                                            :disabled="!item.disabled"
                                                            v-model="item.end_date_m"
                                                            @change="updateDate(item, k)"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid':
                                                                    submittedList && ($v.listData.$each[k].end_date.$error || !checkDate(item))
                                                            }"
                                                        />
                                                        月
                                                        <input
                                                            type="text"
                                                            :disabled="!item.disabled"
                                                            v-model="item.end_date_d"
                                                            @change="updateDate(item, k)"
                                                            style="width: 47px; padding-right: 0 !important"
                                                            class="form-control mx-2"
                                                            :class="{
                                                                'is-invalid':
                                                                    submittedList && ($v.listData.$each[k].end_date.$error || !checkDate(item))
                                                            }"
                                                        />
                                                        日
                                                        <div class="btn-sm btn btn-info mx-2 box-date" style="min-width: 80px">
                                                            カレンダー
                                                            <date-picker
                                                                @close="changeDate(item, k)"
                                                                :disabled="!item.disabled"
                                                                v-model="item.end_date"
                                                                :value-type="customFormatDate"
                                                                :first-day-of-week="1"
                                                                lang="ja"
                                                            ></date-picker>
                                                        </div>
                                                    </div>
                                                    <span
                                                        :class="{
                                                            'is-invalid': submittedList && ($v.listData.$each[k].end_date.$error || !checkDate(item))
                                                        }"
                                                    ></span>
                                                    <div
                                                        v-if="submittedList && ($v.listData.$each[k].end_date.$error || !checkDate(item))"
                                                        class="invalid-feedback"
                                                    >
                                                        <span v-if="!$v.listData.$each[k].end_date.required">形式が正しくありません。</span>
                                                        <span v-if="!$v.listData.$each[k].end_date.validate">形式が正しくありません。</span>
                                                        <span v-if="!checkDate(item)">形式が正しくありません。</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <a
                                                    href="javascript:void(0)"
                                                    v-if="!item.disabled"
                                                    type="button"
                                                    @click="edit(item, k)"
                                                    class="btn btn-info mx-2"
                                                    style="white-space: nowrap"
                                                    >編集</a
                                                >
                                                <button v-else type="submit" class="btn btn-success mx-2" style="white-space: nowrap">保存</button>
                                                <button type="button" @click="deleteObject(item)" class="btn btn-danger" style="white-space: nowrap">
                                                    削除
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                        <hr />
                        <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                            <div class="form-group">
                                <div class="d-flex align-items-center">
                                    <div class="row w-100">
                                        <div class="col-sm-6">
                                            <div class="row align-items-center">
                                                <div class="col-sm-9">
                                                    <input
                                                        type="text"
                                                        @input="inputNumber('title')"
                                                        @keypress="preventInputE"
                                                        v-model="form.title"
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': (submitted && $v.form.title.$error) || validateTitle
                                                        }"
                                                    />
                                                    <div v-if="(submitted && $v.form.title.$error) || validateTitle" class="invalid-feedback">
                                                        <span v-if="!$v.form.title.required">{{
                                                            $t('validateField.required', {
                                                                field: '年度'
                                                            })
                                                        }}</span>
                                                        <span v-if="!$v.form.title.maxLength">
                                                            {{
                                                                $t('validateField.max255', {
                                                                    field: '年度'
                                                                })
                                                            }}
                                                        </span>
                                                        <span v-if="validateTitle">この年度がすでに登録されています。</span>
                                                    </div>
                                                </div>
                                                <label class="col-sm-2">年度</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <label class="col-sm-2 mt-2"></label>
                                                <div class="col-sm-9 ml-0">
                                                    <div class="mb-2">
                                                        <div class="d-flex align-items-center">
                                                            <input
                                                                type="text"
                                                                v-model="form.start_date_y"
                                                                style="width: 80px; padding-right: 0 !important"
                                                                class="form-control mr-2"
                                                                :class="{
                                                                    'is-invalid': submitted && $v.form.start_date.$error
                                                                }"
                                                            />
                                                            年
                                                            <input
                                                                type="text"
                                                                v-model="form.start_date_m"
                                                                style="width: 47px; padding-right: 0 !important"
                                                                class="form-control mx-2"
                                                                :class="{
                                                                    'is-invalid': submitted && $v.form.start_date.$error
                                                                }"
                                                            />
                                                            月
                                                            <input
                                                                type="text"
                                                                v-model="form.start_date_d"
                                                                style="width: 47px; padding-right: 0 !important"
                                                                class="form-control mx-2"
                                                                :class="{
                                                                    'is-invalid': submitted && $v.form.start_date.$error
                                                                }"
                                                            />
                                                            日
                                                            <div class="btn-sm btn btn-info mx-2 box-date" style="min-width: 80px">
                                                                カレンダー
                                                                <date-picker
                                                                    v-model="form.start_date"
                                                                    :value-type="customFormatDate"
                                                                    :first-day-of-week="1"
                                                                    lang="ja"
                                                                ></date-picker>
                                                            </div>
                                                            から
                                                        </div>
                                                        <span
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.start_date.$error
                                                            }"
                                                        ></span>
                                                        <div v-if="submitted && $v.form.start_date.$error" class="invalid-feedback">
                                                            <span v-if="!$v.form.start_date.required">形式が正しくありません。</span>

                                                            <span v-if="!$v.form.start_date.validate">形式が正しくありません。</span>
                                                            <span v-if="!$v.form.start_date.checkDate">形式が正しくありません。</span>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div class="d-flex align-items-center">
                                                            <input
                                                                type="text"
                                                                v-model="form.end_date_y"
                                                                style="width: 80px; padding-right: 0 !important"
                                                                class="form-control mr-2"
                                                                :class="{
                                                                    'is-invalid': submitted && $v.form.end_date.$error
                                                                }"
                                                            />
                                                            年
                                                            <input
                                                                type="text"
                                                                v-model="form.end_date_m"
                                                                style="width: 47px; padding-right: 0 !important"
                                                                class="form-control mx-2"
                                                                :class="{
                                                                    'is-invalid': submitted && $v.form.end_date.$error
                                                                }"
                                                            />
                                                            月
                                                            <input
                                                                type="text"
                                                                v-model="form.end_date_d"
                                                                style="width: 47px; padding-right: 0 !important"
                                                                class="form-control mx-2"
                                                                :class="{
                                                                    'is-invalid': submitted && $v.form.end_date.$error
                                                                }"
                                                            />
                                                            日
                                                            <div class="btn-sm btn btn-info mx-2 box-date" style="min-width: 80px">
                                                                カレンダー
                                                                <date-picker
                                                                    v-model="form.end_date"
                                                                    :value-type="customFormatDate"
                                                                    :first-day-of-week="1"
                                                                    lang="ja"
                                                                ></date-picker>
                                                            </div>
                                                            まで
                                                        </div>
                                                        <span
                                                            :class="{
                                                                'is-invalid': submitted && $v.form.end_date.$error
                                                            }"
                                                        ></span>
                                                        <div v-if="submitted && $v.form.end_date.$error" class="invalid-feedback">
                                                            <span v-if="!$v.form.end_date.required">形式が正しくありません。</span>

                                                            <span v-if="!$v.form.end_date.validate">形式が正しくありません。</span>
                                                            <span v-if="!$v.form.end_date.checkDate">形式が正しくありません。</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center">
                        <button type="submit" class="btn btn-primary" @click="formSubmit">登録</button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
