<template>
    <div>
        <div class="col-sm-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <div v-if="!isPreview">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingApplicationMethod.titleBlock') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_application_method.title_block"
                            :config.sync="configForm.configFieldTitle"
                            @change="handleShotCodeStyle"
                            @blur="handleValidateField(configForm.configFieldTitle)"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationMethod.background') }}</label>
                    <div class="col-sm-9">
                        <div>
                            <UploadImageCommon
                                @dataFile="handleFileBackgroundImgLP"
                                title="ファイルアップロード"
                                :id="`BackgroundImgLPApplicationMethods`"
                                :config="{
                                    maxSize: 2048
                                }"
                            />
                            <div
                                v-if="dataForm.block_application_method.background_image?.path"
                                style="width: 200px; height: 200px"
                                class="position-relative"
                            >
                                <b-img
                                    class=""
                                    thumbnail
                                    fluid
                                    :src="dataForm.block_application_method.background_image?.path"
                                    :alt="dataForm.block_application_method.background_image.origin_name"
                                    style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                                ></b-img>
                                <span
                                    style="cursor: pointer; position: absolute; top: -8px; right: -14px; font-size: 18px; font-weight: 700"
                                    @click="handleDeleteTitleImage"
                                    ><i class="ri-close-line" style="vertical-align: bottom"></i
                                ></span>
                            </div>
                        </div>
                        <div class="my-3">
                            <InputText
                                style="max-width: 100px"
                                :model.sync="dataForm.block_application_method.background_color"
                                :config="{
                                    type: 'color',
                                    classCustom: 'form-control-color',
                                    error: false
                                }"
                                @change="handleShotCodeStyle"
                            />
                        </div>
                        <div>
                            <InputCheckBox
                                :model.sync="dataForm.block_application_method.is_repeat"
                                :id="`check-repeat-background-app_method`"
                                :value="dataForm.block_application_method.is_repeat"
                                :label="`repeat`"
                                @change="handleShotCodeStyle"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationMethod.style') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-application-methods-style`"
                            :value.sync="dataForm.block_application_method.style"
                            :options="listStyleAppMethods"
                            :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                            @select="handleShotCodeStyle"
                        />

                        <!-- <div class="mt-3">
                                <Editor
                                    v-if="isVisibleEditor"
                                    :id="`editorStyleApplicationMethods`"
                                    :isBackgroundImageEditor="false"
                                    :model.sync="dataForm.block_application_method.html_content"
                                    :modelEvents="`change keydown blur focus paste`"
                                    :configInit="configEditor"
                                    @init="onEditorInit"
                                />
                            </div> -->
                    </div>
                </div>
                <div class="card mt-5">
                    <div class="text-right mb-5">
                        <button type="button" class="btn btn-outline-success btn-sm rounded" @click="handleAddMethodApp()">
                            <i class="fa fa-plus"></i>
                            応募方法追加
                        </button>
                    </div>
                </div>

                <Draggable
                    :list="dataForm.block_application_method.application_methods"
                    ghost-class="ghost--applicationMethods"
                    class="overflow-hidden"
                    @start="dragList = true"
                    @end="dragList = false"
                    @change="handleShotCodeStyle"
                >
                    <div
                        v-for="(itemAppMethod, k) in dataForm.block_application_method.application_methods"
                        :key="`appMethods-${k}`"
                        class="card p-2"
                    >
                        <div class="card-body position-relative border-bottom">
                            <div class="d-flex flex-row mt-3">
                                <div class="mr-3">
                                    <div class="d-flex align-items-center justify-content-center h-100">
                                        <InputCheckBox
                                            class="custom-switch"
                                            :model.sync="itemAppMethod.status"
                                            :id="`appMethods-${k}`"
                                            :value="true"
                                            :label="``"
                                            @change="handleShotCodeStyle"
                                        />
                                    </div>
                                </div>
                                <div class="mr-3 col-sm-1" style="min-width: 120px">
                                    <img
                                        loading="lazy"
                                        :src="itemAppMethod?.image?.path"
                                        :alt="itemAppMethod?.image?.origin_name"
                                        class="img-fluid mx-auto d-block rounded h-100 w-100"
                                        style="object-fit: contain"
                                    />
                                </div>
                                <div>
                                    <div class="col-sm-12 p-0">
                                        <h4 v-b-tooltip.hover :title="itemAppMethod.title">
                                            {{ truncateTextTable(itemAppMethod.title, 50) ?? '' }}
                                        </h4>
                                    </div>
                                    <div class="col-sm-12 p-0">
                                        <a target="_blank" :href="itemAppMethod.image?.path"
                                            >{{ truncateTextTable(itemAppMethod.image?.path, 300) ?? '' }}
                                        </a>
                                    </div>
                                    <div class="p-1" v-html="truncateTextTable(itemAppMethod.detail, 500) ?? ''"></div>
                                </div>
                            </div>
                            <div class="position-absolute" style="top: 0; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-outline-warning btn-sm rounded-circle mx-3"
                                    style="width: 30px; height: 30px"
                                    @click="handleEditMethodApp(itemAppMethod, k)"
                                >
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-outline-danger btn-sm rounded-circle"
                                    style="width: 30px; height: 30px"
                                    @click="handleDeleteMethodApp(k)"
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
            <ExportLandingPage v-else :dataForm="dataForm" />

            <ModalActionAppMethod
                v-if="configModalActionAppMethod.model"
                :config="configModalActionAppMethod"
                :dataTitle="formAppMethod"
                @updateData="eventUpdateDataAppMethod"
                @addData="eventAddDataAppMethod"
            />
        </div>
    </div>
</template>

<script>
import { configModalActionAppMethod, STATUS_APPLICATION_METHODS } from './constants';
import { truncateText, tranferShortCode } from '@/utils/format';
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { LIST_STYLE_APPLICATION_METHODS } from '../../styles';

import ModalActionAppMethod from './modal-action.vue';
import Draggable from 'vuedraggable';
import UploadImageCommon from '@/components/Uploads/Image/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
// import Editor from '@/components/Editor/main.vue';
import ExportLandingPage from '../export-page/main.vue';

export default {
    name: 'SettingApplicationMethod',
    components: {
        ModalActionAppMethod,
        Draggable,
        InputText,
        UploadImageCommon,
        InputCheckBox,
        Multiselect,
        // Editor,
        ExportLandingPage
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            configModalActionAppMethod: configModalActionAppMethod,
            formAppMethod: null,
            objEditor: null,
            dragList: false,
            isVisibleEditor: false,
            listStyleAppMethods: LIST_STYLE_APPLICATION_METHODS,
            isPreview: false
            // configEditor: {
            //     height: 600
            // }
        };
    },

    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddMethodApp() {
            this.configModalActionAppMethod.title = this.$t('history.register');
            const handleMakeId =
                this.dataForm.block_application_method.application_methods.length <= 0
                    ? this.dataForm.block_application_method.application_methods.length + 1
                    : Math.max(...this.dataForm.block_application_method.application_methods.map((item) => item.id || item.make_id)) + 1;
            this.formAppMethod = {
                make_id: handleMakeId,
                title: '',
                image: null,
                detail: '',
                status: STATUS_APPLICATION_METHODS[0].value
            };
            this.configModalActionAppMethod.model = true;
        },

        handleEditMethodApp(title, idx) {
            this.configModalActionAppMethod.id = title?.id ?? idx;
            this.configModalActionAppMethod.title = '応募方法編集';
            this.formAppMethod = title;
            this.configModalActionAppMethod.model = true;
        },

        handleDeleteMethodApp(idx) {
            this.dataForm.block_application_method.application_methods.splice(idx, 1);
            this.handleShotCodeStyle();
        },

        eventAddDataAppMethod(titleNew) {
            this.dataForm.block_application_method.application_methods.push(titleNew);
            this.handleShotCodeStyle();
        },
        eventUpdateDataAppMethod(titleNew) {
            const hasConfigId = titleNew?.id ?? titleNew.make_id;
            let indexTitle = this.dataForm.block_application_method.application_methods.findIndex((item) => {
                const hasIdList = item?.id ?? item.make_id;
                return hasIdList == hasConfigId;
            });
            if (indexTitle <= -1) return;
            this.dataForm.block_application_method.application_methods[indexTitle] = titleNew;
            this.handleShotCodeStyle();
        },

        truncateTextTable(text, length) {
            return truncateText(text, length);
        },

        handleFileBackgroundImgLP(file) {
            this.dataForm.block_application_method.background_image = file;
            this.handleShotCodeStyle();
        },

        // onEditorInit(editor) {
        //     this.objEditor = editor;
        // },

        handleShotCodeStyle() {
            if (!this.dataForm.block_application_method.style) return;
            let contentNew = [];
            let { background_color, background_image, is_repeat, application_methods } = this.dataForm.block_application_method;
            let { srcBgr, contents, htmlTitle, stepJoin } = this.dataForm.block_application_method.style;
            application_methods.forEach((itemAppMethod) => {
                const replacements = {
                    タイトル: itemAppMethod.title ?? '',
                    詳細: itemAppMethod.detail ?? '',
                    sourceBgr: srcBgr ?? '',
                    imgTitle: itemAppMethod.image?.path ?? '',
                    display: itemAppMethod?.status ? 'block' : 'none'
                };
                let contentShotCode = tranferShortCode(contents, replacements);
                contentNew.push(contentShotCode);
            });
            let convertContentString = contentNew.join(`<div class="charator--method"><img src="${stepJoin}" alt=""/></div>`);

            let addProper =
                application_methods?.length > 0
                    ? `<div class="block__application--methods" style="background-color: ${background_color ?? ''};background-image: url(${background_image?.path});background-repeat: ${is_repeat ? 'repeat' : 'no-repeat'};">
                       <div>
                            ${this.hashShotCodeTitle(htmlTitle)}
                            ${convertContentString}
                        </div>
                    </div>`
                    : '';
            // this.objEditor.setContent(addProper ?? '');
            this.dataForm.block_application_method.html_content = addProper;
        },

        hashShotCodeTitle(htmlTitle) {
            const replacements1 = {
                titleBlock: this.dataForm.block_application_method.title_block ?? ''
            };
            return tranferShortCode(htmlTitle, replacements1);
        },

        handleDeleteTitleImage() {
            this.dataForm.block_application_method.background_image = null;
            this.handleShotCodeStyle();
        },
        togglePreview() {
            this.isPreview = !this.isPreview;
        }
    },
    watch: {},
    mounted() {
        // setTimeout(() => {
        //     this.isVisibleEditor = true;
        // }, 300);
    }
};
</script>

<style lang="scss" scoped>
.ghost--applicationMethods {
    opacity: 0.5;
    background-color: #f8f9fa;
    border: dashed 1px #ccc;
}
</style>
