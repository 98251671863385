import i18n from '@/i18n';

const configFormPrizeLP = {
    configFieldNamePrize: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingPrize.namePrize'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['required', 'max255']
    },
    configFieldTargetNumber: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingPrize.targetNumber'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['max255']
    }
};

const configFormTitleLP = {
    configFieldTitlePrize: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingPrize.titlePrize'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['required', 'max255']
    },
    configFieldNumberOfWinner: {
        isNumber: true,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingPrize.numberOfWinners'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['max20']
    }
};

const configFormPrizeSettings = () => {
    return {
        configFieldTitle: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingPrize.titleLPforPrize'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        }
    };
};

const configModalActionPrize = {
    id: '',
    title: '',
    model: false,
    scrollable: true,
    hideFooter: false,
    size: '2xl',
    class: 'h-100',
    validate: true
};
const configModalActionTitle = {
    id: '',
    title: '',
    model: false,
    scrollable: true,
    hideFooter: false,
    size: 'lg',
    class: 'h-100',
    validate: true
};

const STATUS_TITLE_PRIZE = [
    {
        id: 1,
        value: true,
        name: '有効'
    },
    {
        id: 2,
        value: false,
        name: '無効'
    }
];

export { configFormPrizeLP, configFormTitleLP, configModalActionPrize, configModalActionTitle, STATUS_TITLE_PRIZE, configFormPrizeSettings };
