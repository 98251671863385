import { render, staticRenderFns } from "./modal-action-block.vue?vue&type=template&id=49fcc355&scoped=true"
import script from "./modal-action-block.vue?vue&type=script&lang=js"
export * from "./modal-action-block.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49fcc355",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/Paldia/FE-Paldia-Dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49fcc355')) {
      api.createRecord('49fcc355', component.options)
    } else {
      api.reload('49fcc355', component.options)
    }
    module.hot.accept("./modal-action-block.vue?vue&type=template&id=49fcc355&scoped=true", function () {
      api.rerender('49fcc355', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/phase2/landing-page/components/setting-blocks/components/modal-action-block.vue"
export default component.exports