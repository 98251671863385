<template>
    <div>
        <div class="col-lg-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <form v-if="!isPreview" class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingFirstView.applicationPeriod') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.application_period.title"
                            :config.sync="configForm.configFieldTitle"
                            @change="handleShotCodeStyle"
                            @blur="handleValidateField(configForm.configFieldTitle)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.startDate') }} </label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.application_period.start_date"
                            :config.sync="configForm.configFieldStartDateTime"
                            @change="handleShotCodeStyle"
                            @blur="handleValidateField(configForm.configFieldStartDateTime)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.endDate') }} </label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.application_period.end_date"
                            :config.sync="configForm.configFieldEndDateTime"
                            @change="handleShotCodeStyle"
                            @blur="handleValidateField(configForm.configFieldEndDateTime)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.selectedStyle') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-application_period-style`"
                            :value.sync="dataForm.application_period.style"
                            :options="listStyleTime"
                            :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                            @select="handleShotCodeStyle('select')"
                        />

                        <div class="mt-3">
                            <Editor
                                v-if="isVisibleEditor"
                                :id="`editorTimeApplication`"
                                :isBackgroundImageEditor="false"
                                :model.sync="dataForm.application_period.html_content"
                                :modelEvents="`change keydown blur focus paste`"
                                :configInit="configEditor"
                                @init="onEditorInit"
                            />
                        </div>
                    </div>
                </div>
            </form>
            <ExportLandingPage v-else :dataForm="dataForm" />
        </div>
    </div>
</template>

<script>
import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { validateEndDate } from '../../handlers';
import { tranferShortCode } from '@/utils/format';
import { LIST_STYLE_TIME } from '../../styles';

import Multiselect from '@/components/Multiselect/main.vue';
import Editor from '@/components/Editor/main.vue';
import ExportLandingPage from '../export-page/main.vue';

export default {
    name: 'SettingTimeApplication',
    components: {
        InputText,
        Multiselect,
        Editor,
        ExportLandingPage
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            listStyleTime: LIST_STYLE_TIME,
            objEditor: null,
            configEditor: {
                height: 600,
                content_style: ''
            },
            isVisibleEditor: false,
            isPreview: false
        };
    },
    methods: {
        handleValidateField(configField, action = []) {
            validateField(configField);
            if (action.includes('compare-date')) {
                validateEndDate(this.configForm[action[1]], this.configForm[action[2]]);
            }
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleShotCodeStyle(type = null) {
            let { title, start_date, end_date, style } = this.dataForm.application_period;
            if (type === 'select') {
                this.dataForm.application_period.html_content = '';
                this.objEditor.setContent('');
                this.configEditor.content_style = style?.cssStyle;
            }
            let contentShotCode = '';
            const replacements = {
                タイトル: title ?? '',
                sourceBgr: style?.srcBgr ?? '',
                開始: start_date ?? '',
                終了: end_date ?? ''
            };
            if (this.dataForm.application_period.html_content) {
                const resultContent = this.transferHTMLToShortcode(this.dataForm.application_period.html_content);
                contentShotCode = tranferShortCode(resultContent, replacements);
            } else {
                contentShotCode = tranferShortCode(style?.contents, replacements);
            }
            this.objEditor.setContent(contentShotCode ?? '');
            if (type === 'select') {
                this.updateEditorStyle(this.objEditor, style?.cssStyle);
            }
            this.dataForm.application_period.html_content = contentShotCode;
        },

        transferHTMLToShortcode(html) {
            const div = document.createElement('div');
            div.innerHTML = html;
            const placeholders = {
                shotTitle: '《タイトル》',
                shotStartTime: '《開始》',
                shotEndTime: '《終了》'
            };

            // Thay thế background-image nếu có
            const sourceBgrElement = div.querySelector('#sourceBgr');
            if (sourceBgrElement) {
                let style = sourceBgrElement.getAttribute('style');
                style = style.replace(/url\((.*?)\)/, `url(《sourceBgr》)`);
                sourceBgrElement.setAttribute('style', style);
            }

            // Thay thế nội dung các phần tử theo id
            Object.keys(placeholders).forEach((id) => {
                const element = div.querySelector(`#${id}`);
                if (element) {
                    let imgHTML = '';
                    // Giữ lại thẻ img nếu có
                    element.querySelectorAll('img').forEach((img) => {
                        imgHTML += img.outerHTML;
                    });
                    // Gán nội dung mới
                    element.innerHTML = imgHTML + placeholders[id];
                }
            });

            // Trả về HTML sau khi thay thế
            return div.innerHTML;
        },

        updateEditorStyle(editor, newCss) {
            const head = editor.contentDocument.head || editor.getDoc().head;
            let styleTag = head.querySelector('#dynamic-editor-style');
            if (!styleTag) {
                styleTag = document.createElement('style');
                styleTag.id = 'dynamic-editor-style';
                head.appendChild(styleTag);
            }
            styleTag.innerHTML = newCss;
        },

        togglePreview() {
            this.isPreview = !this.isPreview;
        },

        onTimeOpenEditor() {
            setTimeout(() => {
                this.isVisibleEditor = true;
                this.configEditor.content_style = this.dataForm.application_period.style?.cssStyle;
            }, 300);
        }
    },
    mounted() {
        this.onTimeOpenEditor();
    }
};
</script>

<style lang="scss" scoped></style>
