<template>
    <div>
        <div class="col-lg-12">
            <div class="d-flex">
                <div class="col-sm-7">
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                            >{{ $t('landingPage.settingTargetProduct.titleBlock') }} <span class="text-danger">*</span></label
                        >
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataForm.block_target_product.title_block"
                                :config.sync="configForm.configFieldTitleBlockTargetProduct"
                                @blur="handleValidateField(configForm.configFieldTitleBlockTargetProduct)"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                            $t('landingPage.settingTargetProduct.backgroundBlock')
                        }}</label>
                        <div class="col-sm-9">
                            <div>
                                <UploadFileCommon
                                    @dataFile="handleFileBackgroundImgLP"
                                    title="ファイルアップロード"
                                    :id="`BackgroundImgTargetProduct`"
                                    :config="{
                                        maxSize: 2048
                                    }"
                                />
                                <div v-if="dataForm.block_target_product.background_image?.origin_name" class="d-flex align-items-center my-2">
                                    <a
                                        target="_blank"
                                        :href="dataForm.block_target_product.background_image?.path"
                                        @click.prevent="
                                            downLoadFile(
                                                dataForm.block_target_product.background_image?.path,
                                                dataForm.block_target_product.background_image?.origin_name
                                            )
                                        "
                                        >{{ dataForm.block_target_product.background_image?.origin_name }}</a
                                    >
                                    <span
                                        class="ml-2"
                                        style="cursor: pointer; font-size: 20px; z-index: 99"
                                        @click="dataForm.block_target_product.background_image = null"
                                        ><i class="ri-close-line" style="vertical-align: bottom"></i
                                    ></span>
                                </div>
                            </div>
                            <div class="my-3">
                                <InputText
                                    style="max-width: 100px"
                                    :model.sync="dataForm.block_target_product.background_color"
                                    :config="{
                                        type: 'color',
                                        classCustom: 'form-control-color',
                                        error: false
                                    }"
                                />
                            </div>
                            <div>
                                <InputCheckBox
                                    :model.sync="dataForm.block_target_product.is_repeat"
                                    :id="`check-repeat-background-target__product`"
                                    :value="dataForm.block_target_product.is_repeat"
                                    :label="`repeat`"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingTargetProduct.styleBlock') }} </label>
                        <div class="col-sm-9">
                            <Multiselect
                                :id="`select-target-product_setting-style`"
                                :value.sync="dataForm.block_target_product.style"
                                :options="listStyleTargetProduct"
                                :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                                @select="handleSelectTypeProduct"
                            />
                        </div>
                    </div>

                    <Draggable
                        :list="listTargetProduct"
                        ghost-class="ghost--targetProduct"
                        class="overflow-hidden"
                        @start="dragList = true"
                        @end="dragList = false"
                        @change="onHandleDrag"
                    >
                        <div v-for="(itemTarget, k) in listTargetProduct" :key="`product-${k}`" class="card p-2">
                            <div class="card-body position-relative border-bottom">
                                <div class="d-flex flex-row mt-3">
                                    <div class="mr-3">
                                        <div class="d-flex align-items-center justify-content-center h-100">
                                            <InputCheckBox
                                                class="custom-switch"
                                                :model.sync="itemTarget.status"
                                                :id="`product-${k}`"
                                                :value="true"
                                                :label="``"
                                            />
                                        </div>
                                    </div>
                                    <div class="mr-3 col-sm-1" style="min-width: 120px">
                                        <img
                                            loading="lazy"
                                            :src="itemTarget?.image?.path"
                                            :alt="itemTarget?.image?.origin_name"
                                            class="img-fluid mx-auto d-block rounded h-100 w-100"
                                            style="object-fit: contain"
                                        />
                                    </div>
                                    <div>
                                        <div class="col-sm-12 p-0">
                                            <h4 v-b-tooltip.hover :title="itemTarget.title">{{ truncateText(itemTarget.title, 50) ?? '' }}</h4>
                                        </div>
                                        <div class="col-sm-12 p-0">
                                            <a target="_blank" :href="itemTarget.image?.path"
                                                >{{ truncateText(itemTarget.image?.path, 300) ?? '' }}
                                            </a>
                                        </div>
                                        <div class="p-1" v-html="truncateText(itemTarget.detail, 500) ?? ''"></div>
                                    </div>
                                </div>
                                <div class="position-absolute" style="top: 0; right: 0">
                                    <button
                                        type="button"
                                        class="btn btn-outline-success btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleAddProduct(k)"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-outline-warning btn-sm rounded-circle mx-3"
                                        style="width: 30px; height: 30px"
                                        @click="handleEditProduct(itemTarget, k)"
                                    >
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-outline-danger btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleDeleteProduct(k)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                <div class="col-sm-5">
                    <PreviewTargetProduct :contentHTML="dataForm.block_target_product.html_content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { handleDownFile, truncateText } from '@/utils/format';

// import Editor from '@/components/Editor/main.vue';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import Draggable from 'vuedraggable';
import PreviewTargetProduct from './preview.vue';

export default {
    name: 'settingTargetProduct',
    components: {
        InputText,
        InputCheckBox,
        // Editor,
        UploadFileCommon,
        Multiselect,
        Draggable,
        PreviewTargetProduct
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            objEditor: null,
            dragList: false,
            configEditor: {
                height: 600
            },
            listStyleTargetProduct: [],
            listTargetProduct: []
        };
    },
    methods: {
        ...truncateText,

        handleValidateField(configField) {
            validateField(configField);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleFileBackgroundImgLP(file) {
            this.dataForm.block_target_product.background_image = file;
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        },

        handleSelectTypeProduct() {
            // this.handleShotCodeStyle();
        },

        onHandleDrag() {
            // this.handleShotCodeStyle();
        },

        handleAddProduct() {},

        handleEditProduct() {},

        handleDeleteProduct() {}
    }
};
</script>

<style lang="scss" scoped></style>
