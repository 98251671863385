<template>
    <div class="body__preview">
        <!-- {{ arrBlocksLP }} -->
        <div v-for="(itemLP, idx) in arrBlocksLP" :key="idx">
            <div class="mb-2" v-html="itemLP.body_html"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExportLandingPage',
    props: {
        listBlockLP: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        arrBlocksLP() {
            return this.$props.listBlockLP?.filter((oBlock) => oBlock.is_visibled);
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.body__preview {
    max-width: 900px;
    margin: 0px auto;
}
</style>
