import i18n from '@/i18n';

const configFormTitleApp = {
    configFieldTitleAppMethod: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingPrize.titlePrize'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['required', 'max255']
    }
};

const configFormApplicationMethods = () => {
    return {
        configFieldTitle: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingApplicationMethod.titleBlock'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        }
    };
};

const configModalActionAppMethod = {
    id: '',
    title: '',
    model: false,
    scrollable: true,
    hideFooter: false,
    size: 'lg',
    class: 'h-100',
    validate: true
};

const STATUS_APPLICATION_METHODS = [
    {
        id: 1,
        value: true,
        name: '有効'
    },
    {
        id: 2,
        value: false,
        name: '無効'
    }
];

export { configFormTitleApp, configModalActionAppMethod, STATUS_APPLICATION_METHODS, configFormApplicationMethods };
