<template>
    <div>
        <div class="col-lg-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <div v-if="!isPreview">
                <!-- <Draggable
                    :list="dataForm.blocks_settings"
                    handle=".handle"
                    ghost-class="ghost--eventPrize"
                    @start="dragList = true"
                    @end="dragList = false"
                >
                  
                </Draggable> -->
                <div v-for="(blockItem, idx) in dataForm.blocks_settings" :key="idx" class="mb-3">
                    <div class="row">
                        <div>
                            <InputCheckBox
                                v-if="blockItem?.block_default"
                                class="custom-switch text-right"
                                :model.sync="blockItem.is_visibled"
                                :id="`checks-switch-${idx}`"
                                :value="blockItem.is_visibled"
                                :label="``"
                            />
                        </div>
                        <div class="col-sm-5">
                            <!-- v-b-tooltip.hover :title="blockItem.name" -->
                            <div class="d-flex">
                                <h5 class="col-sm-7 text-truncate">{{ blockItem.name }}</h5>
                                <button
                                    v-if="['block_free', 'block_button'].includes(blockItem.key)"
                                    type="button"
                                    class="btn btn-outline-warning btn-sm rounded-circle"
                                    style="width: 30px; height: 30px"
                                >
                                    <i class="fa fa-edit"></i>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="col-sm-6">
                            <div class="d-flex align-items-center justify-content-end" style="gap: 3rem">
                                <div class="" style="cursor: pointer">
                                    <i class="fa fa-align-justify handle"></i>
                                </div>
                                <div class="" style="margin-right: 7px">
                                    <button
                                        type="button"
                                        class="btn btn-outline-danger btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleDeleteBlock(idx)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="card">
                    <div class="text-center mb-5">
                        <button type="button" class="btn btn-outline-success btn-sm rounded" @click="handleAddBlocks()">
                            <i class="fa fa-plus"></i>
                            Add block
                        </button>
                    </div>
                </div> -->
            </div>
            <ExportLandingPage v-else :dataForm="dataForm" />
            <ModalActionBlock v-if="configModalActionBlock.model" :config="configModalActionBlock" @evtAdd="onHandleEvtAddBlock" />
        </div>
    </div>
</template>

<script>
// import Draggable from 'vuedraggable';
import ModalActionBlock from './components/modal-action-block.vue';
import ExportLandingPage from '../export-page/main.vue';

import { configModalActionBlock } from './constants';
import { InputCheckBox } from '@/components/Input';

export default {
    name: 'SettingBlockLP',
    components: { ModalActionBlock, InputCheckBox, ExportLandingPage },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            configModalActionBlock: configModalActionBlock,
            isPreview: false
        };
    },
    methods: {
        handleAddBlocks() {
            this.configModalActionBlock.title = 'Add Block';
            this.configModalActionBlock.model = true;
        },

        onHandleEvtAddBlock(dataEvt) {
            let { title, category } = dataEvt;
            this.dataForm.blocks_settings.push({
                order_index: this.dataForm.blocks_settings.length,
                name: title,
                category: category,
                is_visibled: true,
                block_default: false
            });
        },

        handleDeleteBlock(idx) {
            this.dataForm.blocks_settings.splice(idx, 1);
        },

        togglePreview() {
            this.isPreview = !this.isPreview;
        }
    }
};
</script>

<style lang="scss"></style>
