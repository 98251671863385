import i18n from '@/i18n';

const configFormTimeApplication = () => {
    return {
        configFieldTitle: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingFirstView.applicationPeriod'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['required', 'max255']
        },
        configFieldStartDateTime: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingFirstView.startDate'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        },
        configFieldEndDateTime: {
            isNumber: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('landingPage.settingFirstView.endDate'),
            errorCustomMess: '',
            valueSync: '',
            typesValidate: ['max255']
        }
    };
};

export { configFormTimeApplication };
