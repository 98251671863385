<template>
    <div>
        <div class="col-lg-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <div v-if="!isPreview">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingTargetProduct.titleBlock') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.block_target_product.title_block"
                            :config.sync="configForm.configFieldTitleBlockTargetProduct"
                            @blur="handleValidateField(configForm.configFieldTitleBlockTargetProduct)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingTargetProduct.backgroundBlock') }}</label>
                    <div class="col-sm-9">
                        <div>
                            <UploadImageCommon
                                @dataFile="handleFileBackgroundImgLP"
                                title="ファイルアップロード"
                                :id="`BackgroundImgTargetProduct`"
                                :config="{
                                    maxSize: 2048
                                }"
                            />
                            <div
                                v-if="dataForm.block_target_product.background_image?.path"
                                style="width: 200px; height: 200px"
                                class="position-relative"
                            >
                                <b-img
                                    class=""
                                    thumbnail
                                    fluid
                                    :src="dataForm.block_target_product.background_image?.path"
                                    :alt="dataForm.block_target_product.background_image.origin_name"
                                    style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                                ></b-img>
                                <span
                                    style="cursor: pointer; position: absolute; top: -8px; right: -14px; font-size: 18px; font-weight: 700"
                                    @click="handleDeleteTitleImage"
                                    ><i class="ri-close-line" style="vertical-align: bottom"></i
                                ></span>
                            </div>
                        </div>
                        <div class="my-3">
                            <InputText
                                style="max-width: 100px"
                                :model.sync="dataForm.block_target_product.background_color"
                                :config="{
                                    type: 'color',
                                    classCustom: 'form-control-color',
                                    error: false
                                }"
                            />
                        </div>
                        <div>
                            <InputCheckBox
                                :model.sync="dataForm.block_target_product.is_repeat"
                                :id="`check-repeat-background-target__product`"
                                :value="dataForm.block_target_product.is_repeat"
                                :label="`repeat`"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingTargetProduct.styleBlock') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-target-product_setting-style`"
                            :value.sync="dataForm.block_target_product.style"
                            :options="listStyleTargetProduct"
                            :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                            @select="handleSelectTypeProduct"
                        />
                    </div>
                </div>

                <Draggable
                    :list="listTargetProduct"
                    ghost-class="ghost--targetProduct"
                    class="overflow-hidden"
                    @start="dragList = true"
                    @end="dragList = false"
                    @change="onHandleDrag"
                >
                    <div v-for="(itemTarget, k) in listTargetProduct" :key="`product-${k}`" class="card p-2">
                        <div class="card-body position-relative border-bottom">
                            <div class="d-flex flex-row mt-3">
                                <div class="mr-3">
                                    <div class="d-flex align-items-center justify-content-center h-100">
                                        <InputCheckBox
                                            class="custom-switch"
                                            :model.sync="itemTarget.status"
                                            :id="`product-${k}`"
                                            :value="true"
                                            :label="``"
                                        />
                                    </div>
                                </div>
                                <div class="mr-3 col-sm-1" style="min-width: 120px">
                                    <img
                                        loading="lazy"
                                        :src="itemTarget?.image?.path"
                                        :alt="itemTarget?.image?.origin_name"
                                        class="img-fluid mx-auto d-block rounded h-100 w-100"
                                        style="object-fit: contain"
                                    />
                                </div>
                                <div>
                                    <div class="col-sm-12 p-0">
                                        <h4 v-b-tooltip.hover :title="itemTarget.title">{{ truncateText(itemTarget.title, 50) ?? '' }}</h4>
                                    </div>
                                    <div class="col-sm-12 p-0">
                                        <a target="_blank" :href="itemTarget.image?.path">{{ truncateText(itemTarget.image?.path, 300) ?? '' }} </a>
                                    </div>
                                    <div class="p-1" v-html="truncateText(itemTarget.detail, 500) ?? ''"></div>
                                </div>
                            </div>
                            <div class="position-absolute" style="top: 0; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-outline-success btn-sm rounded-circle"
                                    style="width: 30px; height: 30px"
                                    @click="handleAddProduct(k)"
                                >
                                    <i class="fa fa-plus"></i>
                                </button>

                                <button
                                    type="button"
                                    class="btn btn-outline-warning btn-sm rounded-circle mx-3"
                                    style="width: 30px; height: 30px"
                                    @click="handleEditProduct(itemTarget, k)"
                                >
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-outline-danger btn-sm rounded-circle"
                                    style="width: 30px; height: 30px"
                                    @click="handleDeleteProduct(k)"
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>

            <PreviewTargetProduct v-else :contentHTML="dataForm.block_target_product.html_content" />
        </div>
    </div>
</template>

<script>
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { truncateText } from '@/utils/format';
import { LIST_STYLE_TARGET_PRODUCT } from '../../styles';

// import Editor from '@/components/Editor/main.vue';
import UploadImageCommon from '@/components/Uploads/Image/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import Draggable from 'vuedraggable';
import PreviewTargetProduct from './preview.vue';

export default {
    name: 'settingTargetProduct',
    components: {
        InputText,
        InputCheckBox,
        // Editor,
        UploadImageCommon,
        Multiselect,
        Draggable,
        PreviewTargetProduct
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            objEditor: null,
            dragList: false,
            isPreview: false,
            configEditor: {
                height: 600
            },
            listStyleTargetProduct: LIST_STYLE_TARGET_PRODUCT,
            listTargetProduct: []
        };
    },
    methods: {
        ...truncateText,

        handleValidateField(configField) {
            validateField(configField);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleFileBackgroundImgLP(file) {
            this.dataForm.block_target_product.background_image = file;
        },

        handleDeleteTitleImage() {
            this.dataForm.block_target_product.background_image = null;
            // this.handleShotCodeStyle();
        },

        togglePreview() {
            this.isPreview = !this.isPreview;
        },

        handleSelectTypeProduct() {
            // this.handleShotCodeStyle();
        },

        onHandleDrag() {
            // this.handleShotCodeStyle();
        },

        handleAddProduct() {},

        handleEditProduct() {},

        handleDeleteProduct() {}
    }
};
</script>

<style lang="scss" scoped></style>
