<template>
    <div class="body__preview">
        <div v-html="contentHTML"></div>
    </div>
</template>

<script>
export default {
    name: 'PreviewTargetProduct',
    props: {
        contentHTML: {
            type: String,
            required: true,
            default: () => ''
        }
    }
};
</script>

<style lang="scss" scoped>
.body__preview {
    max-width: 900px;
    margin: 0px auto;
}
</style>
