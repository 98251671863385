<template>
    <div>
        <div class="col-lg-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <form v-if="!isPreview" class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.nameCampaign') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.campaign?.name ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.detailFirstView') }} </label>
                    <div class="col-sm-9">
                        <Editor
                            v-if="isVisibleEditor"
                            :id="`editorFirstView`"
                            :isBackgroundImageEditor="true"
                            :model.sync="dataForm.first_view.html_content"
                            :modelEvents="`change keydown blur focus paste`"
                            :configInit="configEditor"
                            @init="onEditorInit"
                        />
                    </div>
                </div>
            </form>
            <ExportLandingPage v-else :dataForm="dataForm" />
        </div>
    </div>
</template>

<script>
import { validateField } from '@/utils/validate';
import { editorMethods } from '@/state/helpers';

import Editor from '@/components/Editor/main.vue';
import ExportLandingPage from '../export-page/main.vue';

export default {
    name: 'SettingFirstViewAndTimeApplication',
    components: {
        Editor,
        ExportLandingPage
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            objEditor: null,
            configEditor: {
                height: 600
            },
            isVisibleEditor: false,
            isPreview: false
        };
    },
    computed: {},
    methods: {
        ...editorMethods,

        handleValidateField(configField) {
            validateField(configField);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },
        togglePreview() {
            this.isPreview = !this.isPreview;
        }
    },
    mounted() {
        setTimeout(() => {
            this.isVisibleEditor = true;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
