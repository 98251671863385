<template>
    <div>
        <div class="col-sm-12">
            <div class="text-right mb-3">
                <button :class="`btn btn-${isPreview ? 'secondary' : 'danger'} `" @click="togglePreview">
                    {{ isPreview ? $t('btn.closePreview') : $t('btn.preview') }}
                </button>
            </div>
            <div v-if="!isPreview">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                        >{{ $t('landingPage.settingPrize.titleLPforPrize') }} <span class="text-danger">*</span></label
                    >
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="dataForm.prize_settings.title"
                            :config.sync="configForm.configFieldTitle"
                            @change="handleShotCodeStyle"
                            @blur="handleValidateField(configForm.configFieldTitle)"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.background') }}</label>
                    <div class="col-sm-9">
                        <div>
                            <UploadImageCommon
                                @dataFile="handleFileBackgroundImgLP"
                                title="ファイルアップロード"
                                :id="`BackgroundImgLP`"
                                :config="{
                                    maxSize: 2048
                                }"
                            />
                            <div v-if="dataForm.prize_settings.background.image?.path" style="width: 200px; height: 200px" class="position-relative">
                                <b-img
                                    class=""
                                    thumbnail
                                    fluid
                                    :src="dataForm.prize_settings.background.image?.path"
                                    :alt="dataForm.prize_settings.background.image.origin_name"
                                    style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                                ></b-img>
                                <span
                                    style="cursor: pointer; position: absolute; top: -8px; right: -14px; font-size: 18px; font-weight: 700"
                                    @click="handleDeleteTitleImage"
                                    ><i class="ri-close-line" style="vertical-align: bottom"></i
                                ></span>
                            </div>
                        </div>
                        <div class="my-3">
                            <InputText
                                style="max-width: 100px"
                                :model.sync="dataForm.prize_settings.background.color"
                                @change="handleShotCodeStyle"
                                :config="{
                                    type: 'color',
                                    classCustom: 'form-control-color',
                                    error: false
                                }"
                            />
                        </div>
                        <div>
                            <InputCheckBox
                                :model.sync="dataForm.prize_settings.is_repeat"
                                :id="`check-repeat-background-prize_lp`"
                                :value="dataForm.prize_settings.is_repeat"
                                :label="`repeat`"
                                @change="handleShotCodeStyle"
                            />
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.selectedStyle') }} </label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-prize_setting-style`"
                            :value.sync="dataForm.prize_settings.style"
                            :options="listStylePrizes"
                            @select="handleShotCodeStyle"
                            :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                        />

                        <!-- <div class="mt-3">
                                <Editor
                                    v-if="isVisibleEditor"
                                    :id="`editorStylePrize`"
                                    :isBackgroundImageEditor="false"
                                    :model.sync="dataForm.prize_settings.html_content"
                                    :modelEvents="`change keydown blur focus paste`"
                                    :configInit="configEditor"
                                    @init="onEditorInit"
                                />
                            </div> -->
                    </div>
                </div>

                <div class="card mt-5">
                    <div class="text-right mb-5">
                        <button type="button" class="btn btn-outline-success btn-sm rounded" @click="handleAddTitle()">
                            <i class="fa fa-plus"></i>
                            賞品タイトルを追加
                        </button>
                    </div>
                </div>

                <Draggable
                    :list="dataForm.prize_settings.prizes"
                    ghost-class="ghost--eventPrize"
                    class="overflow-hidden"
                    @start="dragList = true"
                    @end="dragList = false"
                    @change="handleShotCodeStyle"
                >
                    <div v-for="(evt, k) in dataForm.prize_settings.prizes" :key="`prize-${k}`" class="card p-2">
                        <div class="card-body position-relative border-bottom">
                            <div class="d-flex flex-row mt-3">
                                <div class="mr-3">
                                    <div class="d-flex align-items-center justify-content-center h-100">
                                        <InputCheckBox
                                            class="custom-switch"
                                            :model.sync="evt.status"
                                            :id="`prize-${k}`"
                                            :value="true"
                                            :label="``"
                                            @change="handleShotCodeStyle"
                                        />
                                    </div>
                                </div>
                                <div class="mr-3 col-sm-1" style="min-width: 120px">
                                    <img
                                        loading="lazy"
                                        :src="evt?.image?.path"
                                        :alt="evt?.image?.origin_name"
                                        class="img-fluid mx-auto d-block rounded h-100 w-100"
                                        style="object-fit: contain"
                                    />
                                </div>
                                <div>
                                    <div class="col-sm-12 p-0">
                                        <h4 v-b-tooltip.hover :title="evt.title">{{ truncateTextTable(evt.title, 50) ?? '' }}</h4>
                                    </div>
                                    <div class="col-sm-12 p-0">
                                        <a target="_blank" :href="evt.image?.path">{{ truncateTextTable(evt.image?.path, 300) ?? '' }} </a>
                                    </div>
                                    <div class="p-1" v-html="truncateTextTable(evt.detail, 500) ?? ''"></div>
                                </div>
                            </div>
                            <div class="position-absolute" style="top: 0; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-outline-warning btn-sm rounded-circle mx-3"
                                    style="width: 30px; height: 30px"
                                    @click="handleEditTitle(evt, k)"
                                >
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-outline-danger btn-sm rounded-circle"
                                    style="width: 30px; height: 30px"
                                    @click="handleDeleteTitle(k)"
                                >
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </Draggable>
            </div>
            <ExportLandingPage v-else :dataForm="dataForm" />

            <ModalActionTitle
                v-if="configModalActionTitle.model"
                :config="configModalActionTitle"
                :dataTitle="formTitle"
                @updateData="eventUpdateDataTitle"
                @addData="eventAddDataTitle"
            />
        </div>
    </div>
</template>

<script>
import { configModalActionPrize, configModalActionTitle, STATUS_TITLE_PRIZE } from './constants';
import { truncateText, tranferShortCode } from '@/utils/format';
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { LIST_STYLE_PRIZE } from '../../styles';

import ModalActionTitle from './modal-action-title.vue';
import Draggable from 'vuedraggable';
import UploadImageCommon from '@/components/Uploads/Image/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
// import Editor from '@/components/Editor/main.vue';
import ExportLandingPage from '../export-page/main.vue';

export default {
    name: 'SettingPrizeList',
    components: {
        ModalActionTitle,
        Draggable,
        InputText,
        UploadImageCommon,
        InputCheckBox,
        Multiselect,
        // Editor,
        ExportLandingPage
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            configModalActionPrize: configModalActionPrize,
            configModalActionTitle: configModalActionTitle,
            formTitle: null,
            objEditor: null,
            dragList: false,
            isVisibleEditor: false,
            listStylePrizes: LIST_STYLE_PRIZE,
            isPreview: false
            // configEditor: {
            //     height: 600
            // }
        };
    },

    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        isActiveModalEdit(idActive) {
            return idActive == this.configModalActionPrize.id;
        },

        handleAddTitle() {
            this.configModalActionTitle.title = this.$t('history.register');
            const handleMakeId =
                this.dataForm.prize_settings.prizes.length <= 0
                    ? this.dataForm.prize_settings.prizes.length + 1
                    : Math.max(...this.dataForm.prize_settings.prizes.map((item) => item.id || item.make_id)) + 1;
            this.formTitle = {
                make_id: handleMakeId,
                typeAction: 'Add',
                title: '',
                image: null,
                detail: '',
                number_of_winner: '',
                status: STATUS_TITLE_PRIZE[0].value
            };
            this.configModalActionTitle.model = true;
        },

        handleEditTitle(title, idx) {
            this.configModalActionTitle.id = title?.id ?? idx;
            this.configModalActionTitle.title = '賞品タイトル編集';
            this.formTitle = title;
            this.configModalActionTitle.model = true;
        },

        handleDeleteTitle(idx) {
            this.dataForm.prize_settings.prizes.splice(idx, 1);
            this.handleShotCodeStyle();
        },

        eventAddDataTitle(titleNew) {
            this.dataForm.prize_settings.prizes.push(titleNew);
            this.handleShotCodeStyle();
        },
        eventUpdateDataTitle(titleNew) {
            const hasConfigId = titleNew?.id ?? titleNew.make_id;
            let indexTitle = this.dataForm.prize_settings.prizes.findIndex((item) => {
                const hasIdList = item?.id ?? item.make_id;
                return hasIdList == hasConfigId;
            });
            if (indexTitle <= -1) return;
            this.dataForm.prize_settings.prizes[indexTitle] = titleNew;
            this.handleShotCodeStyle();
        },

        truncateTextTable(text, length) {
            return truncateText(text, length);
        },

        handleFileBackgroundImgLP(file) {
            this.dataForm.prize_settings.background.image = file;
            this.handleShotCodeStyle();
        },

        // onEditorInit(editor) {
        //     this.objEditor = editor;
        // },

        handleShotCodeStyle() {
            if (!this.dataForm.prize_settings.style) return;
            let contentNew = [];
            let { background, is_repeat } = this.dataForm.prize_settings;
            let { srcBgr, contents, htmlTitle, id } = this.dataForm.prize_settings.style;
            this.dataForm.prize_settings.prizes.forEach((itemPrize) => {
                const replacements = {
                    タイトル: itemPrize.title ?? '',
                    詳細: itemPrize.detail ?? '',
                    sourceBgr: srcBgr ?? '',
                    imgTitle: itemPrize.image?.path ?? '',
                    当選人数: itemPrize.number_of_winner || 0,
                    display: itemPrize?.status ? 'block' : 'none'
                };
                let contentShotCode = tranferShortCode(contents, replacements);
                contentNew.push(contentShotCode);
            });
            let convertContentString = contentNew.join(`<div class="spacing__prize __${id}"></div>`);
            let addProper =
                this.dataForm.prize_settings.prizes?.length > 0
                    ? `
                    <div class="block_prizes" style="background-color: ${background?.color ?? ''};background-image: url(${background.image?.path});background-repeat: ${is_repeat ? 'repeat' : 'no-repeat'};">
                       <div>
                            ${this.hashShotCodeTitle(htmlTitle)}
                            ${convertContentString}
                        </div>
                    </div>`
                    : '';
            // this.objEditor.setContent(addProper ?? '');
            this.dataForm.prize_settings.html_content = addProper;
        },

        hashShotCodeTitle(htmlTitle) {
            const replacements1 = {
                titleBlock: this.dataForm.prize_settings.title ?? ''
            };
            return tranferShortCode(htmlTitle, replacements1);
        },

        handleDeleteTitleImage() {
            this.dataForm.prize_settings.background.image = null;
            this.handleShotCodeStyle();
        },

        togglePreview() {
            this.isPreview = !this.isPreview;
        }
    },
    watch: {},
    mounted() {
        // setTimeout(() => {
        //     this.isVisibleEditor = true;
        // }, 300);
    }
};
</script>

<style lang="scss" scoped>
.ghost--eventPrize {
    opacity: 0.5;
    background-color: #f8f9fa;
    border: dashed 1px #ccc;
}
</style>
