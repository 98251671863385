<template>
    <div>
        <ModalCommon :id="`configModalActionTitle-${config?.id ?? ''}`" :config="config" @save="handleSave()" @close="handleClose()">
            <!-- タイトル -->
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" for="adsfasđs" style="min-width: 150px"
                    >{{ $t('landingPage.settingPrize.titlePrize') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <InputText
                        :model.sync="dataFormTitle.title"
                        :config.sync="configFormTitleLP.configFieldTitlePrize"
                        @blur="handleValidateField(configFormTitleLP.configFieldTitlePrize)"
                    />
                </div>
            </div>
            <!-- 画像 -->
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" for="adsfasđs" style="min-width: 150px"
                    >{{ $t('landingPage.settingPrize.imagePrize') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <UploadImagesCommon
                        @dataFile="handleFileBackgroundEditor"
                        title="ファイルアップロード"
                        :id="`ImgTitlePrize`"
                        :config="{
                            maxSize: 5120
                        }"
                    />
                    <div
                        v-if="dataFormTitle.image?.path && arrExtension.includes(dataFormTitle.image.extension)"
                        style="width: 200px; height: 200px"
                        class="position-relative"
                    >
                        <b-img
                            class=""
                            thumbnail
                            fluid
                            :src="dataFormTitle.image?.path"
                            :alt="dataFormTitle.image.origin_name"
                            style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                        ></b-img>
                        <span
                            style="cursor: pointer; position: absolute; top: -8px; right: -14px; font-size: 18px; font-weight: 700"
                            @click="handleDeleteTitleImage"
                            ><i class="ri-close-line" style="vertical-align: bottom"></i
                        ></span>
                    </div>
                    <div v-if="isRequiredImage" class="invalid-feedback d-block">
                        {{
                            $t('validateField.requiredSelect', {
                                field: $t('landingPage.settingPrize.imagePrize')
                            })
                        }}
                    </div>
                </div>
            </div>
            <!-- 詳細 -->
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.detailPrize') }} </label>
                <div class="col-sm-9">
                    <Editor
                        v-if="isVisibleEditor"
                        :id="`configModalActionTitle-${config?.id ?? ''}`"
                        :model.sync="dataFormTitle.detail"
                        :modelEvents="`change keydown blur focus paste`"
                        :configInit="configEditor"
                        @init="onEditorInit"
                    />
                </div>
            </div>
            <!-- 当選人数 -->
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.numberOfWinners') }} </label>
                <div class="col-sm-9">
                    <InputText
                        :model.sync="dataFormTitle.number_of_winner"
                        :config.sync="configFormTitleLP.configFieldNumberOfWinner"
                        @blur="handleValidateField(configFormTitleLP.configFieldNumberOfWinner)"
                    />
                </div>
            </div>
            <!-- ステータス -->
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.status') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex">
                        <div v-for="item in STATUS_TITLE_PRIZE" :key="item.id + 's'">
                            <InputRadio
                                class="mr-4"
                                :model.sync="dataFormTitle.status"
                                :name="`statusTitlePrize`"
                                :id="`statusTitlePrize-${item.id}`"
                                :value="item.value"
                                :label="item.name"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import { InputText, InputRadio } from '@/components/Input';
import { configFormTitleLP, STATUS_TITLE_PRIZE } from './constants';
import { validateField, validateAllField } from '@/utils/validate';
import { IMAGE_EXTENSIONS } from '@/utils/format';

import ModalCommon from '@/components/Modal/main.vue';
import UploadImagesCommon from '@/components/Uploads/Image/main.vue';
import Editor from '@/components/Editor/main.vue';

export default {
    name: 'ModalEditPrize',
    components: {
        InputText,
        ModalCommon,
        UploadImagesCommon,
        Editor,
        InputRadio
    },
    props: {
        config: {
            type: Object,
            required: true,
            default: () => null
        },
        dataTitle: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            STATUS_TITLE_PRIZE: STATUS_TITLE_PRIZE,
            configFormTitleLP: configFormTitleLP,
            dataFormTitle: this.dataTitle,
            arrExtension: IMAGE_EXTENSIONS,
            dataFormTitleCopy: JSON.parse(JSON.stringify(this.dataTitle)),
            isVisibleEditor: false,
            isRequiredImage: false,
            objEditor: null,
            configEditor: {
                height: 600
            }
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleSave() {
            let checkValidate = validateAllField(configFormTitleLP);
            this.isRequiredImage = !this.dataFormTitle.image;
            if (!checkValidate || this.isRequiredImage) return;
            if (this.dataFormTitle?.id || this.dataFormTitle?.actionTitle == 'Edit') {
                this.$emit('updateData', this.dataFormTitle);
            } else {
                this.dataFormTitle.actionTitle = 'Edit';
                this.$emit('addData', this.dataFormTitle);
            }
            this.config.model = false;
        },

        handleClose() {
            let { configFieldTitlePrize } = this.configFormTitleLP;
            configFieldTitlePrize.error = false;
            this.dataFormTitle = this.dataFormTitleCopy;
            this.$emit('updateData', this.dataFormTitle);
            this.config.model = false;
        },

        renderListLv1(idOrigin, checkIdLv1) {
            return {
                check: idOrigin.id === checkIdLv1,
                itemLv1: idOrigin.data
            };
        },

        handleFileBackgroundEditor(file) {
            this.dataFormTitle.image = file;
            this.isRequiredImage = false;
        },

        handleDeleteTitleImage() {
            this.dataFormTitle.image = null;
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        }
    },
    watch: {
        'config.model': {
            handler: function (newShow) {
                if (newShow) {
                    this.dataFormTitleCopy = JSON.parse(JSON.stringify(this.dataTitle));
                    this.isLoading = true;
                    setTimeout(() => {
                        this.isVisibleEditor = true;
                        this.isLoading = false;
                    }, 200);
                } else {
                    this.isVisibleEditor = false;
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
