<template>
    <div class="body__preview">
        <!-- {{ arrBlocksLP }} -->
        <div v-for="(itemLP, idx) in arrBlocksLP" :key="idx">
            <div v-if="itemLP.key !== 'block_share'" class="mb-2" v-html="itemLP.body_html"></div>
            <div v-else id="shareBlock" :style="onStyleShare()" class="block_share d-flex justify-content-center">
                <div v-for="(itemSocial, idx) in activeSocial" :key="`social-${idx}`">
                    <div>
                        <a
                            class="ela--share"
                            :href="onLinkShare(itemSocial.urlShare)"
                            target="_blank"
                            :style="`background-color: ${itemSocial.backgroundColor}`"
                        >
                            <img loading="lazy" :src="itemSocial.logo" :alt="itemSocial.logo" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleMapHTML } from '../../handlers';
import { SOCIAL_MEDIA_SHARE } from '../../constants';

export default {
    name: 'ExportLandingPage',
    props: {
        listBlockLP: {
            type: Array,
            default: () => []
        },
        dataForm: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        arrBlocksLP() {
            const prevs = this.$props.listBlockLP?.length > 0 ? this.$props.listBlockLP : this.dataBlockPreview;
            return prevs?.filter((oBlock) => oBlock.is_visibled);
        },
        activeSocial() {
            return SOCIAL_MEDIA_SHARE?.filter((oBlock) => oBlock.active);
        }
    },
    data() {
        return {
            dataBlockPreview: [],
            title: 'Landing page',
            description: 'Hướng dẫn Vue.js chi tiết',
            image: 'https://i.pinimg.com/736x/5f/40/93/5f4093a1a0083660632923880cd3dbc3.jpg',
            url: 'https://paldia-dev.supenient.vn'
        };
    },
    page() {
        let { name, og_image, block_share } = this.dataForm;
        return {
            title: name ?? 'Trang chủ - My Website',
            meta: [
                { property: 'og:title', content: name ?? 'Trang chủ - My Website' },
                { property: 'og:description', content: block_share?.share_text ?? 'Mô tả ngắn về trang chủ.' },
                { property: 'og:image', content: `${og_image?.path ?? this.image}` },
                { property: 'og:url', content: 'https://paldia-dev.supenient.vn' }
            ]
        };
    },
    methods: {
        handleMapPreview() {
            this.dataBlockPreview =
                this.dataForm?.blocks_settings?.map((itemBlockSet) => {
                    itemBlockSet.key = itemBlockSet?.key === 'block_prize' ? 'prize_settings' : itemBlockSet.key;
                    return {
                        block_default: itemBlockSet.block_default,
                        name: itemBlockSet.name,
                        order_index: itemBlockSet.order_index,
                        is_visibled: itemBlockSet.is_visibled,
                        category: itemBlockSet?.category ?? '',
                        key: itemBlockSet.key ?? '',
                        body_html: handleMapHTML(itemBlockSet.key, this.dataForm)
                    };
                }) ?? [];
        },

        onStyleShare() {
            let { background_image, background_color, is_repeat } = this.dataForm?.block_share;
            return `background-color: ${background_color ?? ''};
                                                                background-image: url(${background_image?.path ?? ''});
                                                                background-repeat: ${is_repeat ? 'repeat' : 'no-repeat'};
                                                                overflow: hidden;
                                                                background-blend-mode: multiply;`;
        },

        onLinkShare(shareUrl) {
            let { block_share } = this.dataForm;
            let hashtags = block_share?.hashtag;
            let query = `${shareUrl}${this.url}`;
            if (hashtags?.length > 0) {
                query = `${shareUrl}${this.url}&hashtag=%23${hashtags.map((num) => `${num}`).join('%20%23')}`;
            }
            return query;
        }
    },
    mounted() {
        this.handleMapPreview();
        // this.$nextTick(() => {
        //     console.log('>>> Mounted xong, ép cập nhật meta');
        //     this.$meta().refresh();
        // });
    }
};
</script>

<style lang="scss" scoped></style>
