<template>
    <div>
        <div class="col-lg-12">
            <div class="d-flex">
                <div class="col-lg-7">
                    <form class="needs-validation" autocomplete="off">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.applicationPeriod') }} <span class="text-danger">*</span></label
                            >
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.application_period.title"
                                    :config.sync="configForm.configFieldTitle"
                                    @input="handleShotCodeStyle"
                                    @blur="handleValidateField(configForm.configFieldTitle)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.startDate') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.application_period.start_date"
                                    :config.sync="configForm.configFieldStartDateTime"
                                    @input="handleShotCodeStyle"
                                    @blur="handleValidateField(configForm.configFieldStartDateTime)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.endDate') }} </label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="dataForm.application_period.end_date"
                                    :config.sync="configForm.configFieldEndDateTime"
                                    @input="handleShotCodeStyle"
                                    @blur="handleValidateField(configForm.configFieldEndDateTime)"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.selectedStyle') }}
                            </label>
                            <div class="col-sm-9">
                                <Multiselect
                                    :id="`select-application_period-style`"
                                    :value.sync="dataForm.application_period.style"
                                    :options="listStyleTime"
                                    @select="handleSelectTypeTime"
                                    :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                                />

                                <div class="mt-3">
                                    <Editor
                                        v-if="isVisibleEditor"
                                        :id="`editorTimeApplication`"
                                        :isBackgroundImageEditor="false"
                                        :model.sync="dataForm.application_period.html_content"
                                        :modelEvents="`change keydown blur focus paste`"
                                        :configInit="configEditor"
                                        @init="onEditorInit"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-sm-5">
                    <PreviewTimeApplication :contentHTML="dataForm.application_period.html_content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { InputText } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { validateEndDate } from '../../handlers';
import { tranferShortCode } from '@/utils/format';
import { LIST_STYLE_TIME } from '../../constants';

import Multiselect from '@/components/Multiselect/main.vue';
import Editor from '@/components/Editor/main.vue';
import PreviewTimeApplication from './preview.vue';

export default {
    name: 'SettingTimeApplication',
    components: {
        InputText,
        Multiselect,
        Editor,
        PreviewTimeApplication
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            listStyleTime: LIST_STYLE_TIME,
            objEditor: null,
            configEditor: {
                height: 600
            },
            isVisibleEditor: false
        };
    },
    methods: {
        handleValidateField(configField, action = []) {
            validateField(configField);
            if (action.includes('compare-date')) {
                validateEndDate(this.configForm[action[1]], this.configForm[action[2]]);
            }
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        },

        handleSelectTypeTime() {
            this.handleShotCodeStyle();
        },

        handleShotCodeStyle() {
            let { title, start_date, end_date, style } = this.dataForm.application_period;
            const replacements = {
                タイトル: title ?? '',
                sourceBgr: style.srcBgr ?? '',
                開始: start_date ?? '',
                終了: end_date ?? ''
            };
            let contentShotCode = tranferShortCode(style?.contents, replacements);
            this.objEditor.setContent(contentShotCode ?? '');
            this.dataForm.application_period.html_content = contentShotCode;
        }
    },
    mounted() {
        setTimeout(() => {
            this.isVisibleEditor = true;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
