<template>
    <div>
        <div class="col-sm-12">
            <div class="d-flex">
                <div class="col-sm-7">
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                            >{{ $t('landingPage.settingApplicationMethod.titleBlock') }} <span class="text-danger">*</span></label
                        >
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataForm.block_application_method.title_block"
                                :config.sync="configForm.configFieldTitle"
                                @blur="handleValidateField(configForm.configFieldTitle)"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{
                            $t('landingPage.settingApplicationMethod.background')
                        }}</label>
                        <div class="col-sm-9">
                            <div>
                                <UploadFileCommon
                                    @dataFile="handleFileBackgroundImgLP"
                                    title="ファイルアップロード"
                                    :id="`BackgroundImgLPApplicationMethods`"
                                    :config="{
                                        maxSize: 2048
                                    }"
                                />
                                <div v-if="dataForm.block_application_method.background_image?.origin_name" class="d-flex align-items-center my-2">
                                    <a
                                        target="_blank"
                                        :href="dataForm.block_application_method.background_image?.path"
                                        @click.prevent="
                                            downLoadFile(
                                                dataForm.block_application_method.background_image?.path,
                                                dataForm.block_application_method.background_image?.origin_name
                                            )
                                        "
                                        >{{ dataForm.block_application_method.background_image?.origin_name }}</a
                                    >
                                    <span
                                        class="ml-2"
                                        style="cursor: pointer; font-size: 20px"
                                        @click="dataForm.block_application_method.background_image = null"
                                        ><i class="ri-close-line" style="vertical-align: bottom"></i
                                    ></span>
                                </div>
                            </div>
                            <div class="my-3">
                                <InputText
                                    style="max-width: 100px"
                                    :model.sync="dataForm.block_application_method.background_color"
                                    :config="{
                                        type: 'color',
                                        classCustom: 'form-control-color',
                                        error: false
                                    }"
                                />
                            </div>
                            <div>
                                <InputCheckBox
                                    :model.sync="dataForm.block_application_method.is_repeat"
                                    :id="`check-repeat-background`"
                                    :value="dataForm.block_application_method.is_repeat"
                                    :label="`repeat`"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationMethod.style') }} </label>
                        <div class="col-sm-9">
                            <Multiselect
                                :id="`select-application-methods-style`"
                                :value.sync="dataForm.block_application_method.style"
                                :options="listStyleAppMethods"
                                :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                                @select="handleSelectTypeApplicationMethods"
                            />

                            <!-- <div class="mt-3">
                                <Editor
                                    v-if="isVisibleEditor"
                                    :id="`editorStyleApplicationMethods`"
                                    :isBackgroundImageEditor="false"
                                    :model.sync="dataForm.block_application_method.html_content"
                                    :modelEvents="`change keydown blur focus paste`"
                                    :configInit="configEditor"
                                    @init="onEditorInit"
                                />
                            </div> -->
                        </div>
                    </div>
                    <div v-if="listApplicationMethods?.length <= 0" class="card mt-5">
                        <div class="text-center mb-5">
                            <button type="button" class="btn btn-outline-success btn-sm rounded" @click="handleAddMethodApp()">
                                <i class="fa fa-plus"></i>
                                {{ $t('btn.addTag') }}
                            </button>
                        </div>
                    </div>

                    <Draggable
                        :list="listApplicationMethods"
                        ghost-class="ghost--applicationMethods"
                        class="overflow-hidden"
                        @start="dragList = true"
                        @end="dragList = false"
                        @change="onHandleDrag"
                    >
                        <div v-for="(itemAppMethod, k) in listApplicationMethods" :key="`appMethods-${k}`" class="card p-2">
                            <div class="card-body position-relative border-bottom">
                                <div class="d-flex flex-row mt-3">
                                    <div class="mr-3">
                                        <div class="d-flex align-items-center justify-content-center h-100">
                                            <InputCheckBox
                                                class="custom-switch"
                                                :model.sync="itemAppMethod.status"
                                                :id="`appMethods-${k}`"
                                                :value="true"
                                                :label="``"
                                            />
                                        </div>
                                    </div>
                                    <div class="mr-3 col-sm-1" style="min-width: 120px">
                                        <img
                                            loading="lazy"
                                            :src="itemAppMethod?.image?.path"
                                            :alt="itemAppMethod?.image?.origin_name"
                                            class="img-fluid mx-auto d-block rounded h-100 w-100"
                                            style="object-fit: contain"
                                        />
                                    </div>
                                    <div>
                                        <div class="col-sm-12 p-0">
                                            <h4 v-b-tooltip.hover :title="itemAppMethod.title">
                                                {{ truncateTextTable(itemAppMethod.title, 50) ?? '' }}
                                            </h4>
                                        </div>
                                        <div class="col-sm-12 p-0">
                                            <a target="_blank" :href="itemAppMethod.image?.path"
                                                >{{ truncateTextTable(itemAppMethod.image?.path, 300) ?? '' }}
                                            </a>
                                        </div>
                                        <div class="p-1" v-html="truncateTextTable(itemAppMethod.detail, 500) ?? ''"></div>
                                    </div>
                                </div>
                                <div class="position-absolute" style="top: 0; right: 0">
                                    <button
                                        type="button"
                                        class="btn btn-outline-success btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleAddMethodApp(k)"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-outline-warning btn-sm rounded-circle mx-3"
                                        style="width: 30px; height: 30px"
                                        @click="handleEditMethodApp(itemAppMethod, k)"
                                    >
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-outline-danger btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleDeleteMethodApp(k)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                <div class="col-sm-5">
                    <PreviewAppMethod :contentHTML="dataForm.block_application_method.html_content" />
                </div>
            </div>

            <ModalActionAppMethod
                v-if="configModalActionAppMethod.model"
                :config="configModalActionAppMethod"
                :dataTitle="formAppMethod"
                @updateData="eventUpdateDataAppMethod"
                @addData="eventAddDataAppMethod"
            />
        </div>
    </div>
</template>

<script>
import { configModalActionAppMethod, STATUS_APPLICATION_METHODS } from './constants';
import { truncateText, handleDownFile, tranferShortCode } from '@/utils/format';
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { LIST_STYLE_APPLICATION_METHODS } from '../../constants';

import ModalActionAppMethod from './modal-action.vue';
import Draggable from 'vuedraggable';
import UploadFileCommon from '@/components/Uploads/Files/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
// import Editor from '@/components/Editor/main.vue';
import PreviewAppMethod from './preview.vue';

export default {
    name: 'SettingApplicationMethod',
    components: {
        ModalActionAppMethod,
        Draggable,
        InputText,
        UploadFileCommon,
        InputCheckBox,
        Multiselect,
        // Editor,
        PreviewAppMethod
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            configModalActionAppMethod: configModalActionAppMethod,
            formAppMethod: null,
            objEditor: null,
            dragList: false,
            isVisibleEditor: false,
            listApplicationMethods: [],
            listStyleAppMethods: LIST_STYLE_APPLICATION_METHODS
            // configEditor: {
            //     height: 600
            // }
        };
    },

    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleAddMethodApp() {
            this.configModalActionAppMethod.title = this.$t('history.register');
            this.formAppMethod = {
                idxTitle: this.listApplicationMethods.length,
                title: '',
                image: null,
                detail: '',
                status: STATUS_APPLICATION_METHODS[0].value
            };
            this.configModalActionAppMethod.model = true;
        },

        handleEditMethodApp(title, idx) {
            this.configModalActionAppMethod.id = title?.id ?? idx;
            this.configModalActionAppMethod.title = 'Edit Application Methods';
            this.formAppMethod = title;
            this.configModalActionAppMethod.model = true;
        },

        handleDeleteMethodApp(idx) {
            this.listApplicationMethods.splice(idx, 1);
            this.handleMapAppMethod(this.listApplicationMethods);
        },

        eventAddDataAppMethod(titleNew) {
            this.listApplicationMethods.push(titleNew);
            this.handleMapAppMethod(this.listApplicationMethods);
        },
        eventUpdateDataAppMethod(titleNew) {
            const hasConfigId = titleNew?.id ?? titleNew.idxTitle;
            let indexTitle = this.listApplicationMethods.findIndex((item) => {
                const hasIdList = item?.id ?? item.idxTitle;
                return hasIdList == hasConfigId;
            });
            if (indexTitle <= -1) return;
            this.listApplicationMethods[indexTitle] = titleNew;
            this.handleMapAppMethod(this.listApplicationMethods);
        },

        handleMapAppMethod(listApplicationMethods) {
            this.dataForm.block_application_method.application_methods = listApplicationMethods.map((itemAppMethod) => {
                return {
                    ...(itemAppMethod.id !== null &&
                        itemAppMethod.id !== undefined && {
                            id: itemAppMethod.id
                        }),
                    title: itemAppMethod.title,
                    image: itemAppMethod.image,
                    detail: itemAppMethod.detail,
                    status: itemAppMethod.status
                };
            });
            this.handleShotCodeStyle();
        },

        truncateTextTable(text, length) {
            return truncateText(text, length);
        },

        handleFileBackgroundImgLP(file) {
            this.dataForm.block_application_method.background_image = file;
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        },

        // onEditorInit(editor) {
        //     this.objEditor = editor;
        // },

        handleSelectTypeApplicationMethods() {
            this.handleShotCodeStyle();
        },

        handleShotCodeStyle() {
            if (!this.dataForm.block_application_method.style) return;
            let contentNew = [];
            let { srcBgr, contents } = this.dataForm.block_application_method.style;
            // imgSection
            this.listApplicationMethods.forEach((itemPrize) => {
                const replacements = {
                    タイトル: itemPrize.title ?? '',
                    詳細: itemPrize.detail ?? '',
                    sourceBgr: srcBgr ?? '',
                    imgTitle: itemPrize.image?.path ?? ''
                };
                let contentShotCode = tranferShortCode(contents, replacements);
                contentNew.push(contentShotCode);
            });
            let convertContentString = contentNew.join();
            // let addProper =
            //     this.listApplicationMethods?.length > 0
            //         ? `<div class="block_prizes"><div style="display: flex; justify-content: center; align-items: center"><div><img src="${imgSection}" alt="" style="width: 100%; height: 100%; margin-bottom: 12px" />${convertContentString}</div></div></div>`
            //         : '';
            // this.objEditor.setContent(addProper ?? '');
            this.dataForm.block_application_method.html_content = convertContentString;
        },

        onHandleDrag() {
            this.handleShotCodeStyle();
        }
    },
    watch: {
        'dataForm.block_application_method.application_methods': {
            handler: function (newArray) {
                this.listApplicationMethods = newArray ?? [];
            },
            immediate: true
        }
    },
    mounted() {
        // setTimeout(() => {
        //     this.isVisibleEditor = true;
        // }, 300);
    }
};
</script>

<style lang="scss" scoped>
.ghost--applicationMethods {
    opacity: 0.5;
    background-color: #f8f9fa;
    border: dashed 1px #ccc;
}
</style>
