<template>
    <div>
        <ModalCommon :id="`configModalActionAppMethods-${config?.id ?? ''}`" :config="config" @save="handleSave()" @close="handleClose()">
            <!-- 応募方法名  -->
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" for="" style="min-width: 150px"
                    >{{ $t('landingPage.settingApplicationMethod.titleAppMethod') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <InputText
                        :model.sync="dataFormAppMethod.title"
                        :config.sync="configFormTitleApp.configFieldTitleAppMethod"
                        @blur="handleValidateField(configFormTitleApp.configFieldTitleAppMethod)"
                    />
                </div>
            </div>
            <!-- 画像  -->
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" for="" style="min-width: 150px"
                    >{{ $t('landingPage.settingApplicationMethod.image') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <UploadImagesCommon
                        @dataFile="handleFileBackgroundEditor"
                        title="ファイルアップロード"
                        :id="`ImgTitleAppMethods`"
                        :config="{
                            maxSize: 5120
                        }"
                    />
                    <div
                        v-if="dataFormAppMethod.image?.path && arrExtension.includes(dataFormAppMethod.image.extension)"
                        style="width: 200px; height: 200px"
                        class="position-relative"
                    >
                        <b-img
                            class=""
                            thumbnail
                            fluid
                            :src="dataFormAppMethod.image?.path"
                            :alt="dataFormAppMethod.image.origin_name"
                            style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                        ></b-img>
                        <span
                            style="cursor: pointer; position: absolute; top: -8px; right: -14px; font-size: 18px; font-weight: 700"
                            @click="handleDeleteAppMethod"
                            ><i class="ri-close-line" style="vertical-align: bottom"></i
                        ></span>
                    </div>
                    <div v-if="isRequiredImage" class="invalid-feedback d-block">
                        {{
                            $t('validateField.requiredSelect', {
                                field: $t('landingPage.settingApplicationMethod.image')
                            })
                        }}
                    </div>
                </div>
            </div>
            <!-- ステータス -->
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationMethod.status') }} </label>
                <div class="col-sm-9">
                    <div class="d-flex">
                        <div v-for="item in STATUS_APPLICATION_METHODS" :key="item.id + 's'">
                            <InputRadio
                                class="mr-4"
                                :model.sync="dataFormAppMethod.status"
                                :name="`statusTitlePrize`"
                                :id="`statusTitlePrize-${item.id}`"
                                :value="item.value"
                                :label="item.name"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 詳細が必要 -->
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationMethod.detail') }} </label>
                <div class="col-sm-9">
                    <Editor
                        v-if="isVisibleEditor"
                        :id="`configModalActionAppMethods-${config?.id ?? ''}`"
                        :model.sync="dataFormAppMethod.detail"
                        :modelEvents="`change keydown blur focus paste`"
                        :configInit="configEditor"
                        @init="onEditorInit"
                    />
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import { InputText, InputRadio } from '@/components/Input';
import { configFormTitleApp, STATUS_APPLICATION_METHODS } from './constants';
import { validateField, validateAllField } from '@/utils/validate';
import { IMAGE_EXTENSIONS } from '@/utils/format';

import ModalCommon from '@/components/Modal/main.vue';
import UploadImagesCommon from '@/components/Uploads/Image/main.vue';
import Editor from '@/components/Editor/main.vue';

export default {
    name: 'ModalActionAppMethod',
    components: {
        InputText,
        ModalCommon,
        UploadImagesCommon,
        Editor,
        InputRadio
    },
    props: {
        config: {
            type: Object,
            required: true,
            default: () => null
        },
        dataTitle: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            STATUS_APPLICATION_METHODS: STATUS_APPLICATION_METHODS,
            configFormTitleApp: configFormTitleApp,
            dataFormAppMethod: this.dataTitle,
            arrExtension: IMAGE_EXTENSIONS,
            dataFormTitleCopy: JSON.parse(JSON.stringify(this.dataTitle)),
            isVisibleEditor: false,
            isRequiredImage: false,
            objEditor: null,
            configEditor: {
                height: 600
            }
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        handleSave() {
            let checkValidate = validateAllField(configFormTitleApp);
            this.isRequiredImage = !this.dataFormAppMethod.image;
            if (!checkValidate || this.isRequiredImage) return;
            if (this.dataFormAppMethod?.id || (this.dataFormAppMethod?.make_id && this.dataFormAppMethod?.actionTitle == 'Edit')) {
                this.$emit('updateData', this.dataFormAppMethod);
            } else {
                this.dataFormAppMethod.actionTitle = 'Edit';
                this.$emit('addData', this.dataFormAppMethod);
            }
            this.config.model = false;
        },

        handleClose() {
            let { configFieldTitleAppMethod } = this.configFormTitleApp;
            configFieldTitleAppMethod.error = false;
            this.dataFormAppMethod = this.dataFormTitleCopy;
            this.$emit('updateData', this.dataFormAppMethod);
            this.config.model = false;
        },

        renderListLv1(idOrigin, checkIdLv1) {
            return {
                check: idOrigin.id === checkIdLv1,
                itemLv1: idOrigin.data
            };
        },

        handleFileBackgroundEditor(file) {
            this.dataFormAppMethod.image = file;
            this.isRequiredImage = false;
        },

        handleDeleteAppMethod() {
            this.dataFormAppMethod.image = null;
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        }
    },
    watch: {
        'config.model': {
            handler: function (newShow) {
                if (newShow) {
                    this.dataFormTitleCopy = JSON.parse(JSON.stringify(this.dataTitle));
                    this.isLoading = true;
                    setTimeout(() => {
                        this.isVisibleEditor = true;
                        this.isLoading = false;
                    }, 200);
                } else {
                    this.isVisibleEditor = false;
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
