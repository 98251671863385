<template>
    <div>
        <div class="col-lg-12">
            <div class="d-flex">
                <div class="col-lg-7">
                    <form class="needs-validation" autocomplete="off">
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.nameCampaign') }}
                            </label>
                            <div class="col-sm-9">
                                {{ dataForm?.campaign?.name ?? '' }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                                >{{ $t('landingPage.settingFirstView.detailFirstView') }}
                            </label>
                            <div class="col-sm-9">
                                <Editor
                                    v-if="isVisibleEditor"
                                    :id="`editorFirstView`"
                                    :isBackgroundImageEditor="true"
                                    :model.sync="dataForm.first_view.html_content"
                                    :modelEvents="`change keydown blur focus paste`"
                                    :configInit="configEditor"
                                    @init="onEditorInit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-sm-5">
                    <PreviewFirst :contentHTML="dataForm.first_view.html_content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validateField } from '@/utils/validate';
import { handleDownFile, IMAGE_EXTENSIONS } from '@/utils/format';
import { editorMethods } from '@/state/helpers';

import Editor from '@/components/Editor/main.vue';
import PreviewFirst from './preview.vue';

export default {
    name: 'SettingFirstViewAndTimeApplication',
    components: {
        Editor,
        PreviewFirst
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            arrExtension: IMAGE_EXTENSIONS,
            objEditor: null,
            configEditor: {
                height: 600
            },
            isVisibleEditor: false
        };
    },
    computed: {},
    methods: {
        ...editorMethods,

        handleValidateField(configField) {
            validateField(configField);
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        }
    },
    mounted() {
        setTimeout(() => {
            this.isVisibleEditor = true;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
