<template>
    <div>
        <div class="col-sm-12">
            <div class="d-flex">
                <div class="col-sm-7">
                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                            >{{ $t('landingPage.settingPrize.titleLPforPrize') }} <span class="text-danger">*</span></label
                        >
                        <div class="col-sm-9">
                            <InputText
                                :model.sync="dataForm.prize_settings.title"
                                :config.sync="configForm.configFieldTitle"
                                @blur="handleValidateField(configForm.configFieldTitle)"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.background') }}</label>
                        <div class="col-sm-9">
                            <div>
                                <UploadImageCommon
                                    @dataFile="handleFileBackgroundImgLP"
                                    title="ファイルアップロード"
                                    :id="`BackgroundImgLP`"
                                    :config="{
                                        maxSize: 2048
                                    }"
                                />
                                <div
                                    v-if="dataForm.prize_settings.background.image?.path"
                                    style="width: 200px; height: 200px"
                                    class="position-relative"
                                >
                                    <b-img
                                        class=""
                                        thumbnail
                                        fluid
                                        :src="dataForm.prize_settings.background.image?.path"
                                        :alt="dataForm.prize_settings.background.image.origin_name"
                                        style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                                    ></b-img>
                                    <span
                                        style="cursor: pointer; position: absolute; top: -8px; right: -14px; font-size: 18px; font-weight: 700"
                                        @click="handleDeleteTitleImage"
                                        ><i class="ri-close-line" style="vertical-align: bottom"></i
                                    ></span>
                                </div>
                            </div>
                            <div class="my-3">
                                <InputText
                                    style="max-width: 100px"
                                    :model.sync="dataForm.prize_settings.background.color"
                                    @change="handleChangeColor"
                                    :config="{
                                        type: 'color',
                                        classCustom: 'form-control-color',
                                        error: false
                                    }"
                                />
                            </div>
                            <div>
                                <InputCheckBox
                                    :model.sync="dataForm.prize_settings.is_repeat"
                                    :id="`check-repeat-background`"
                                    :value="dataForm.prize_settings.is_repeat"
                                    :label="`repeat`"
                                    @change="handleChangeRepeat"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.selectedStyle') }} </label>
                        <div class="col-sm-9">
                            <Multiselect
                                :id="`select-prize_setting-style`"
                                :value.sync="dataForm.prize_settings.style"
                                :options="listStylePrizes"
                                :config="{ error: false, isRemove: true, loading: false, trackBy: 'id', label: 'name' }"
                                @select="handleSelectTypePrize"
                            />

                            <!-- <div class="mt-3">
                                <Editor
                                    v-if="isVisibleEditor"
                                    :id="`editorStylePrize`"
                                    :isBackgroundImageEditor="false"
                                    :model.sync="dataForm.prize_settings.html_content"
                                    :modelEvents="`change keydown blur focus paste`"
                                    :configInit="configEditor"
                                    @init="onEditorInit"
                                />
                            </div> -->
                        </div>
                    </div>

                    <div v-if="listEventPrize?.length <= 0" class="card mt-5">
                        <div class="text-center mb-5">
                            <button type="button" class="btn btn-outline-success btn-sm rounded" @click="handleAddTitle()">
                                <i class="fa fa-plus"></i>
                                賞品タイトルを追加
                            </button>
                        </div>
                    </div>

                    <Draggable
                        :list="listEventPrize"
                        ghost-class="ghost--eventPrize"
                        class="overflow-hidden"
                        @start="dragList = true"
                        @end="dragList = false"
                        @change="onHandleDrag"
                    >
                        <div v-for="(evt, k) in listEventPrize" :key="`prize-${k}`" class="card p-2">
                            <div class="card-body position-relative border-bottom">
                                <div class="d-flex flex-row mt-3">
                                    <div class="mr-3">
                                        <div class="d-flex align-items-center justify-content-center h-100">
                                            <InputCheckBox
                                                class="custom-switch"
                                                :model.sync="evt.status"
                                                :id="`prize-${k}`"
                                                :value="true"
                                                :label="``"
                                                @change="handleChangeStatusPrize"
                                            />
                                        </div>
                                    </div>
                                    <div class="mr-3 col-sm-1" style="min-width: 120px">
                                        <img
                                            loading="lazy"
                                            :src="evt?.image?.path"
                                            :alt="evt?.image?.origin_name"
                                            class="img-fluid mx-auto d-block rounded h-100 w-100"
                                            style="object-fit: contain"
                                        />
                                    </div>
                                    <div>
                                        <div class="col-sm-12 p-0">
                                            <h4 v-b-tooltip.hover :title="evt.title">{{ truncateTextTable(evt.title, 50) ?? '' }}</h4>
                                        </div>
                                        <div class="col-sm-12 p-0">
                                            <a target="_blank" :href="evt.image?.path">{{ truncateTextTable(evt.image?.path, 300) ?? '' }} </a>
                                        </div>
                                        <div class="p-1" v-html="truncateTextTable(evt.detail, 500) ?? ''"></div>
                                    </div>
                                </div>
                                <div class="position-absolute" style="top: 0; right: 0">
                                    <button
                                        type="button"
                                        class="btn btn-outline-success btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleAddTitle(k)"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-outline-warning btn-sm rounded-circle mx-3"
                                        style="width: 30px; height: 30px"
                                        @click="handleEditTitle(evt, k)"
                                    >
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-outline-danger btn-sm rounded-circle"
                                        style="width: 30px; height: 30px"
                                        @click="handleDeleteTitle(k)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                <div class="col-sm-5">
                    <PreviewPrizeTitle :contentHTML="dataForm.prize_settings.html_content" />
                </div>
            </div>

            <ModalActionTitle
                v-if="configModalActionTitle.model"
                :config="configModalActionTitle"
                :dataTitle="formTitle"
                @updateData="eventUpdateDataTitle"
                @addData="eventAddDataTitle"
            />
        </div>
    </div>
</template>

<script>
import { configModalActionPrize, configModalActionTitle, STATUS_TITLE_PRIZE } from './constants';
import { truncateText, handleDownFile, tranferShortCode } from '@/utils/format';
import { InputText, InputCheckBox } from '@/components/Input';
import { validateField } from '@/utils/validate';
import { LIST_STYLE_PRIZE } from '../../constants';

import ModalActionTitle from './modal-action-title.vue';
import Draggable from 'vuedraggable';
import UploadImageCommon from '@/components/Uploads/Image/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
// import Editor from '@/components/Editor/main.vue';
import PreviewPrizeTitle from './preview.vue';

export default {
    name: 'SettingPrizeList',
    components: {
        ModalActionTitle,
        Draggable,
        InputText,
        UploadImageCommon,
        InputCheckBox,
        Multiselect,
        // Editor,
        PreviewPrizeTitle
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        },
        configForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            configModalActionPrize: configModalActionPrize,
            configModalActionTitle: configModalActionTitle,
            formTitle: null,
            objEditor: null,
            dragList: false,
            isVisibleEditor: false,
            listEventPrize: [],
            listStylePrizes: LIST_STYLE_PRIZE
            // configEditor: {
            //     height: 600
            // }
        };
    },

    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },

        isActiveModalEdit(idActive) {
            return idActive == this.configModalActionPrize.id;
        },

        handleAddTitle() {
            this.configModalActionTitle.title = this.$t('history.register');
            this.formTitle = {
                idxTitle: this.listEventPrize.length,
                title: '',
                image: null,
                detail: '',
                number_of_winner: '',
                status: STATUS_TITLE_PRIZE[0].value
            };
            this.configModalActionTitle.model = true;
        },

        handleEditTitle(title, idx) {
            this.configModalActionTitle.id = title?.id ?? idx;
            this.configModalActionTitle.title = 'Edit Title';
            this.formTitle = title;
            this.configModalActionTitle.model = true;
        },

        handleDeleteTitle(idx) {
            this.listEventPrize.splice(idx, 1);
            this.handleMapPrizes(this.listEventPrize);
        },

        eventAddDataTitle(titleNew) {
            this.listEventPrize.push(titleNew);
            this.handleMapPrizes(this.listEventPrize);
        },
        eventUpdateDataTitle(titleNew) {
            const hasConfigId = titleNew?.id ?? titleNew.idxTitle;
            let indexTitle = this.listEventPrize.findIndex((item) => {
                const hasIdList = item?.id ?? item.idxTitle;
                return hasIdList == hasConfigId;
            });
            if (indexTitle <= -1) return;
            this.listEventPrize[indexTitle] = titleNew;
            this.handleMapPrizes(this.listEventPrize);
        },

        handleMapPrizes(listEventPrize) {
            this.dataForm.prize_settings.prizes = listEventPrize.map((evt) => {
                return {
                    ...(evt.id !== null &&
                        evt.id !== undefined && {
                            id: evt.id
                        }),
                    title: evt.title,
                    image: evt.image,
                    detail: evt.detail,
                    number_of_winner: evt.number_of_winner,
                    status: evt.status
                };
            });
            this.handleShotCodeStyle();
        },

        truncateTextTable(text, length) {
            return truncateText(text, length);
        },

        handleFileBackgroundImgLP(file) {
            this.dataForm.prize_settings.background.image = file;
            this.handleShotCodeStyle();
        },

        downLoadFile(path, name) {
            if (path) return handleDownFile(path, name);
        },

        // onEditorInit(editor) {
        //     this.objEditor = editor;
        // },

        handleSelectTypePrize() {
            this.handleShotCodeStyle();
        },

        handleShotCodeStyle() {
            if (!this.dataForm.prize_settings.style) return;
            let contentNew = [];
            let { srcBgr, contents, imgSection } = this.dataForm.prize_settings.style;
            this.listEventPrize.forEach((itemPrize) => {
                const replacements = {
                    タイトル: itemPrize.title ?? '',
                    詳細: itemPrize.detail ?? '',
                    sourceBgr: srcBgr ?? '',
                    imgTitle: itemPrize.image?.path ?? '',
                    当選人数: itemPrize.number_of_winner || 0,
                    display: itemPrize?.status ? 'block' : 'none'
                };
                let contentShotCode = tranferShortCode(contents, replacements);
                contentNew.push(contentShotCode);
            });
            let convertContentString = contentNew.join();
            let addProper =
                this.listEventPrize?.length > 0
                    ? `<div class="block_prizes" style="background-color: ${this.dataForm.prize_settings.background?.color ?? ''};background-image: url(${this.dataForm.prize_settings.background.image?.path});background-repeat: ${this.dataForm.prize_settings.is_repeat ? 'repeat' : 'no-repeat'};padding: 10px 20px;"><div style="display: flex; justify-content: center; align-items: center;"><div><img src="${imgSection}" alt="" style="width: 100%; height: 100%; margin-bottom: 12px" />${convertContentString}</div></div></div>`
                    : '';
            // this.objEditor.setContent(addProper ?? '');
            this.dataForm.prize_settings.html_content = addProper;
        },

        onHandleDrag() {
            this.handleShotCodeStyle();
        },

        handleChangeStatusPrize() {
            this.handleShotCodeStyle();
        },

        handleDeleteTitleImage() {
            this.dataForm.prize_settings.background.image = null;
            this.handleShotCodeStyle();
        },

        handleChangeColor() {
            this.handleShotCodeStyle();
        },

        handleChangeRepeat() {
            this.handleShotCodeStyle();
        }
    },
    watch: {
        'dataForm.prize_settings.prizes': {
            handler: function (newArray) {
                this.listEventPrize = newArray.map((itNew) => {
                    if (!itNew?.id || itNew?.id === null || itNew?.id === undefined) {
                        itNew.actionTitle = 'Edit';
                    }
                    return itNew;
                });
            },
            immediate: true
        }
    },
    mounted() {
        // setTimeout(() => {
        //     this.isVisibleEditor = true;
        // }, 300);
    }
};
</script>

<style lang="scss" scoped>
.ghost--eventPrize {
    opacity: 0.5;
    background-color: #f8f9fa;
    border: dashed 1px #ccc;
}
</style>
