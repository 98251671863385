import { render, staticRenderFns } from "./modal-action.vue?vue&type=template&id=cc136b14&scoped=true"
import script from "./modal-action.vue?vue&type=script&lang=js"
export * from "./modal-action.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc136b14",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/paldia-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cc136b14')) {
      api.createRecord('cc136b14', component.options)
    } else {
      api.reload('cc136b14', component.options)
    }
    module.hot.accept("./modal-action.vue?vue&type=template&id=cc136b14&scoped=true", function () {
      api.rerender('cc136b14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/phase2/landing-page/components/setting-application_method/modal-action.vue"
export default component.exports